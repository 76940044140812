import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  objectData: [],
  notesData: [],
  notesObjData: [],
  preddefNotesData: [],
  tagsData: [],
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setObjectsData(state, action) {
      state.objectData = action.payload;
    },
    setNotesData(state, action) {
      state.notesData = action.payload;
    },
    setNotesObjData(state, action) {
      state.notesObjData = action.payload;
    },
    setPreddefNotesData(state, action) {
      state.preddefNotesData = action.payload;
    },
    setTagsData(state, action) {
      state.tagsData = action.payload;
    },
    clearAllData(state) {
      state.objectData = [];
    },
  },
});

export const dataAction = dataSlice.actions;

export default dataSlice;
