import { Alert, Box, Button, TextField, Typography, Link } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

const SingUpFormular = ({ handleSubmit, error, addingUser = false }) => {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        m: 3,
      }}
    >
      {!addingUser && (
        <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
          Registrácia
        </Typography>
      )}
      <TextField
        variant="outlined"
        label="Email"
        name="email"
        id="email"
        autoComplete="email"
        fullWidth
        required
      />
      <Typography variant="h4" sx={{ mb: 2 }}></Typography>
      <TextField
        required
        type="password"
        id="password"
        name="password"
        variant="outlined"
        label="Heslo"
        fullWidth
      />
      <TextField
        sx={{ mt: 2 }}
        required
        type="password"
        id="password-repeat"
        name="password_repeat"
        variant="outlined"
        label="Heslo - overenie"
        fullWidth
      />
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Meno a priezvisko"
        name="name"
        id="name"
        autoComplete="name"
        fullWidth
        required
      />
      <TextField
        sx={{ mt: 2 }}
        variant="outlined"
        label="Licencia"
        name="licence"
        id="licence"
        fullWidth
      />

      {error && (
        <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
          {error}
        </Alert>
      )}
      <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
        {addingUser ? "Vytvor účet" : "Registruj sa"}
      </Button>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {!addingUser && (
          <Link component={RouterLink} to="/login" variant="body2">
            Máš účet? Tak sa prihlás
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default SingUpFormular;
