import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Modal,
  useMediaQuery,
  CircularProgress,
  List,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import NoteItem from "../Notes/NoteItem";
import ConfirmDialog from "../UI/ConfirmDialog";
import notesApi from "../../api/notesApi";
import EditObjectModal from "./EditObjectModal";
import EditNoteModal from "../Notes/EditNoteModal";

const ObjectNotesModal = ({
  objData,
  open,
  handleClose,
  fetchNotes,
  fetchObjects,
  editNoteHandler,
  ...rest
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const [openDeleteHandler, setOpenDeleteHandler] = React.useState(false);
  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
  const [noteId, setNodeId] = React.useState(-1);
  const [openNewNote, setOpenNewNote] = React.useState(false);

  const handleCloseNewNoteFormular = (value) => {
    if (value) {
      fetchNotes(objData.id);
    }
    setOpenNewNote(false);
  };
  const handleOpenNewNoteFormular = () => {
    setOpenNewNote(true);
  };

  const handleCloseDeleteHandler = () => {
    setOpenDeleteHandler(false);
  };
  const onNoteDeleteHandler = (id) => {
    setNodeId(id);
    setOpenDeleteHandler(true);
  };
  const confirmAgreeHandler = async () => {
    const response = await notesApi.delete("/api/note/" + noteId);

    if (response.status === 200) {
      setNodeId(-1);
      fetchNotes(objData.id);
    } else {
      alert("Nastala chyba pri mazani poznamky: " + response.status);
    }
  };
  const onEditNoteHandler = (id) => {
    editNoteHandler(id);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "70%" : "95%",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };
  let objContent = (
    <Box
      sx={{
        ...style,
        display: "flex",
        justifyContent: "center",

        alignItems: "center",
        minHeight: 400,
      }}
    >
      <CircularProgress size={100} />
    </Box>
  );

  if (objData) {
    const objDetailsContent = (
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflow: "auto",
          maxHeight: innerHeight * 0.8 - 150,
          "& ul": { padding: 0 },
        }}
      >
        {objData.notes.map((note) => {
          return (
            <NoteItem
              key={note.id}
              id={note.id}
              isPreddef={false}
              created_at={note.scan_at}
              content={note.content}
              object_name={note.object_name}
              duration={note.duration}
              person_count={note.person_count}
              editHandler={onEditNoteHandler}
              deleteHandler={onNoteDeleteHandler}
              tags={note.tags}
            />
          );
        })}
      </List>
    );
    objContent = (
      <Box sx={style}>
        <Box
          sx={{
            flexDirection: matches ? "row" : "column",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant={matches ? "h6" : "h6"}
            component="h2"
          >
            {`Detaily: ${objData.name} - ${objData.description}`}
          </Typography>
          <Box
            sx={{
              flexDirection: matches ? "row" : "column",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <EditObjectModal
              matches={matches}
              fetchObjects={fetchObjects}
              values={{
                id: objData.id,
                objName: objData.name,
                description: objData.description,
              }}
            />
            <EditNoteModal
              showButton={true}
              open={openNewNote}
              openHandler={handleOpenNewNoteFormular}
              handleClose={handleCloseNewNoteFormular}
              values={objData}
              matches={matches}
            />
          </Box>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} variant="h5">
          Poznámky
        </Typography>
        {objDetailsContent}
        <Button fullWidth onClick={handleClose}>
          Zavri
        </Button>
      </Box>
    );
  }

  useEffect(() => {
    const handleResize = () => {
      setInnerHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {objContent}
      </Modal>
      <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete zmazať poznánku?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      />
    </React.Fragment>
  );
};

export default ObjectNotesModal;
