import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";

export default function TagPickerModal({
  selectedTags,
  tagsSubmitted,
  addingNote,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [tags, setTags] = React.useState([]);

  const [tagName, setTagName] = React.useState("");
  const [color, setColor] = React.useState("#000000");

  let currentyChecked = 0;
  for (const t of tags) {
    if (t.checked) {
      currentyChecked++;
    }
  }

  const handleClickOpen = async () => {
    setLoading(true);
    try {
      const response = await notesApi.get("/api/tag");
      const tmpTag = response.data.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      for (let tag of tmpTag) {
        for (const selected of selectedTags) {
          if (selected.name === tag.name) {
            tag.checked = true;
          }
        }
      }

      setTags(tmpTag);
      setLoading(false);
    } catch (err) {
      setError("Chyba fetch preddef notes: " + err.message);
      setLoading(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setTagName("");
    setColor("#000000");
  };

  const handleSubmit = (event) => {
    tagsSubmitted(
      tags.filter((item) => {
        return item.checked;
      })
    );
    handleClose();
  };

  const handleSubmitTag = async (event) => {
    event.preventDefault();
    setError(null);
    if (tagName === "") {
      setError(`Je potrebný zadať názov tagu`);
      return;
    }
    for (const tag of tags) {
      if (tag.name === tagName) {
        setError(`Chyba! Tag s názvom: ${tagName} už existuje`);
        return;
      }
    }
    try {
      let data = {
        name: tagName,
        color: color,
      };

      const response = await notesApi.post("/api/tag", {
        ...data,
      });

      if (response.status === 200) {
        handleClickOpen();
        setTagName("");
      } else {
        throw new Error(response.status);
      }
    } catch (err) {
      setError("Chyba ukladania tag: " + handelError(err));
    }
  };

  let content = (
    <List>
      {tags.map((item, index) => {
        return (
          <ListItem
            key={index}
            sx={{ cursor: "pointer" }}
            secondaryAction={
              <Checkbox
                edge="end"
                onChange={(value) => {
                  //item.checked = value.target.checked;
                  let tmpArr = JSON.parse(JSON.stringify(tags));
                  for (let t of tmpArr) {
                    if (t.name === item.name) {
                      t.checked = value.target.checked;
                    }
                  }
                  setTags(tmpArr);
                }}
                checked={item.checked}
                inputProps={{ "aria-labelledby": item.name }}
              />
            }
          >
            <Box
              sx={{
                mr: 1,
                bgcolor: item.color,
                borderRadius: 2,
                width: 20,
                height: 20,
              }}
              key={item.name}
            ></Box>
            <ListItemText>{item.name}</ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
  let addTag = (
    <Box>
      <TextField
        id="tagName"
        label="Pridaj tag"
        variant="outlined"
        sx={{ mt: 1 }}
        value={tagName}
        onChange={(event) => {
          setTagName(event.target.value);
        }}
      />
      <input
        type="color"
        id="color-picker"
        variant="standard"
        value={color}
        onChange={(e) => {
          setColor(e.target.value);
        }}
        style={{
          marginTop: 20,
          marginLeft: 10,
          width: 50,
        }}
      />
      <Button onClick={handleSubmitTag}>Pridaj</Button>
    </Box>
  );
  if (!addingNote) {
    addTag = null;
  }
  if (loading) {
    content = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Button
        variant="contained"
        color={selectedTags.length ? "secondary" : "primary"}
        onClick={handleClickOpen}
      >
        {addingNote ? "Vyber" : "Filter podľa tagu"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Vyber tagy</DialogTitle>
          <DialogContent>
            <Box>
              {addTag}
              {error && (
                <Alert sx={{ mt: 2 }} variant="filled" severity="error">
                  {error}
                </Alert>
              )}
              {content}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            {!addingNote && (
              <Button
                onClick={() => {
                  const setter = currentyChecked ? false : true;
                  setTags(
                    tags.map((t) => {
                      t.checked = setter;
                      return t;
                    })
                  );
                }}
              >
                {currentyChecked ? "Zruš označenie" : "Označ všetky"}
              </Button>
            )}
            <Button onClick={handleSubmit}>Potvrď výber</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
