import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useHistory } from "react-router-dom";
import { formatDate } from "./helperFunctions";

const UserInfo = ({ userData }) => {
  const history = useHistory();
  let expire = "nie je aktivovaná";
  if (userData.licence?.expire !== 0) {
    if (userData.licence?.expire < new Date().getTime()) {
      expire = ` vypršala (${userData.licence?.expireHuman})`;
    } else {
      expire = ` ${formatDate(userData.licence?.expireHuman, false)}`;
    }
  } else {
    expire = " časovo neobmedzená";
  }

  return (
    <Card>
      <CardHeader
        avatar={<AccountCircleIcon color="primary" sx={{ fontSize: 40 }} />}
        title={userData.email}
        subheader={`Účet aktivovaný: ${userData.isActive ? "Áno" : "Nie"}`}
        action={
          <Button
            onClick={() => {
              history.push("/user/" + userData.id);
            }}
          >
            Uprav
          </Button>
        }
      />
      <CardContent>
        <Typography>{userData.name}</Typography>
        <Typography>Platnosť licencie: {expire}</Typography>
      </CardContent>
    </Card>
  );
};

export default UserInfo;
