import * as React from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import InfoIcon from "@mui/icons-material/Info";
import { handelError } from "../Helpers/helperFunctions";

export default function ObjectDetail({ objectId, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [details, setDetails] = React.useState([]);

  const handleClickOpen = async () => {
    setOpen(true);
    setLoading(true);
    try {
      const response = await notesApi.get("/api/user/syncProvider/" + objectId);
      setDetails(response.data.syncData || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(handelError(error));
    }
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    handleClose();
  };

  let dialogContent = (
    <DialogContent>
      {details.map((item, index) => {
        let boldText = "";
        let text = "";
        if (Array.isArray(item)) {
          boldText = item[0].display;
          text += item[0].value;
          for (let i = 0; i < item.length; i++) {
            if (i > 0) {
              text += ", " + item[i].display + ": " + item[i].value;
            }
          }
        } else {
          boldText = item.display;
          text = item.value;
        }

        if (text !== "") {
          return (
            <Typography key={index}>
              <b>{boldText}:</b> {text}
            </Typography>
          );
        } else {
          return null;
        }
      })}
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </DialogContent>
  );
  if (loading) {
    dialogContent = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <div>
      <IconButton aria-label="Pridaj" size="small" onClick={handleClickOpen}>
        <InfoIcon size={14} />{" "}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Detailné informácie o objekte</DialogTitle>
        <form onSubmit={handleSubmit}>
          {dialogContent}
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zavri
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
