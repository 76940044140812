import * as React from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";

export default function DeleteConfirmation({ user, load, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (event.target.username.value === user.email) {
      try {
        const response = await notesApi.delete("/api/admin/user" + user.id);

        if (response.status === 200) {
          handleClose();
          load();
        } else {
          throw new Error(response.status);
        }
      } catch (err) {
        setError("Chyba pri odstranovani uzivatela: " + handelError(err));
      }
    }
  };
  return (
    <div>
      <Button
        sx={{ ml: 1 }}
        onClick={handleClickOpen}
        color="error"
        variant="contained"
      >
        Vymaž
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Potvrď zmazanie</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography>
              Pre zmazanie používatela napíš nižšie užívatelove meno (
              {user.email})
            </Typography>
            <TextField
              id="username"
              label="Email pouzivatela"
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            />
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Vymaž</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
