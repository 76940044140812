import React from "react";
import { TextField, Alert } from "@mui/material";

const ObjectForm = ({ values = {}, error, ...rest }) => {
  return (
    <React.Fragment>
      <TextField
        margin="dense"
        id="objName"
        name="objName"
        label="Názov objektu"
        variant="standard"
        defaultValue={values.objName || ""}
        fullWidth
        required
      />
      <TextField
        margin="dense"
        id="description"
        name="description"
        label="Popis objektu"
        variant="standard"
        defaultValue={values.description || ""}
        inputProps={{ inputMode: "numeric" }}
        fullWidth
        required
      />
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
    </React.Fragment>
  );
};

export default ObjectForm;
