import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import api from "../api/notesApi";
import { authAction } from "../store/auth-slice";
import { handelError } from "../components/Helpers/helperFunctions";
import EditUserData from "../components/Helpers/EditUserData";
import { dataAction } from "../store/data-slice";
import { useHistory } from "react-router-dom";

const UserEditScreen = () => {
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [errorPwd, setErrorPwd] = React.useState(null);
  const [errorLicence, setErrorLicence] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const topPanelDirection = useMediaQuery((theme) => theme.breakpoints.up("md"))
    ? "row"
    : "column";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await api.get("/api/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(handelError(err));
    }
  }, [dispatch]);

  const handleLicenceSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorLicence(null);
    try {
      const response = await api.post("/api/user/activateLicence", {
        licenceNumber: event.target.licenceNumber.value,
      });
      if (response.status === 200) {
        setLoading(false);
        alert(
          `${response.data.message}, Pre dokončenie sa musíte znova prihlásiť. Teraz budete odhlásený.`
        );
        dispatch(dataAction.clearAllData());
        dispatch(authAction.clearToken());
        history.replace("/login");
      }
    } catch (error) {
      setErrorLicence(handelError(error));
      setLoading(false);
    }
  };

  const handleLicenceRenewal = async () => {
    setLoading(true);
    setErrorLicence(null);

    try {
      const response = await api.post("/api/user/renewalLicence", {
        licenceNumber: userData.licenceNumber,
      });
      if (response.status === 200) {
        getCurrentUser();
        alert(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      setErrorLicence(handelError(error));
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorPwd(null);
    try {
      const response = await api.post("/api/user/change-password", {
        password: event.target.newPassword.value,
        password_repeat: event.target.newPasswordRepeat.value,
      });
      if (response.status === 200) {
        alert(response.data.message);
      }
    } catch (error) {
      setErrorPwd(handelError(error));
    }
    setLoading(false);
  };

  if (!userData) {
    return <Typography>Chyba, reload</Typography>;
  }

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  //alignSelf: "center",
  // Heslo1234
  const licence = userData.licence;
  const dataChangeElement = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card sx={{ mt: 2, p: 2 }}>
        <CardHeader title="Podrobnosti užívateľa" />
        <CardContent>
          <Typography variant="h6">Meno: {userData.name}</Typography>
          <Typography variant="h6">Email: {userData.email}</Typography>
          <Typography variant="h6">
            Účet aktivovaný: {userData.isActive ? "Áno" : "Nie"}
          </Typography>
          <Typography variant="h6">
            Čislo licencie:{" "}
            <b>
              {userData.licenceNumber !== "undefined"
                ? userData.licenceNumber
                : ""}
            </b>
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <EditUserData values={userData} onEdit={getCurrentUser} />
        </CardActions>
      </Card>
    </Box>
  );
  const passwordChangeElement = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card sx={{ mt: 2, maxWidth: 400 }}>
        <CardHeader title="Formular zmeny hesla" />
        <CardContent component="form" onSubmit={handleSubmit}>
          <TextField
            sx={{ mt: 2 }}
            required
            autoComplete="new-password"
            type="password"
            id="newPassword"
            name="newPassword"
            variant="outlined"
            label="Nove heslo"
            fullWidth
          />
          <TextField
            sx={{ mt: 2 }}
            required
            autoComplete="new-password"
            inputProps={{
              autoComplete: "new-password",
            }}
            type="password"
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            variant="outlined"
            label="Nove heslo - overenie"
            fullWidth
          />
          {errorPwd && (
            <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
              {errorPwd}
            </Alert>
          )}
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button type="submit" variant="contained">
              Potvrd zmenu
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Box>
  );
  const licenceDetailElement = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card sx={{ mt: 2, minWidth: 300, p: 2 }}>
        <CardHeader title="Licencia" />
        {userData.isActive && userData.licenceNumber !== "undefined" ? (
          <React.Fragment>
            <CardContent>
              <Typography variant="h6">
                Čislo licencie: <b>{userData.licenceNumber}</b>
              </Typography>
              <Typography
                variant="h6"
                color={userData.licenceActive ? "black" : "red"}
              >
                Platnoť do:{" "}
                <b>
                  {licence.expire === 0 ? " neobmedzene" : licence.expireHuman}
                </b>
              </Typography>
              <Typography variant="h6">
                Počet objektov: <b>{licence.liftCount}</b>
              </Typography>
              <Typography variant="h6">
                Počet revíznych technikov: <b>{licence.technicianCount}</b>
              </Typography>
              <Typography variant="h6">
                Ostáva počet aktivácii: <b>{licence.activationRequestLeft}</b>
              </Typography>
              <Typography variant="h6">
                Ostáva počet aktivácii desktop:{" "}
                <b>{licence.activationPdfRequestLeft}</b>
              </Typography>
              <Typography variant="h6">
                Ostáva počet obnovení: <b>{licence.renewalRequestLeft}</b>
              </Typography>
              <Typography variant="h6">
                Synchronizácia desktop aplikácie:{" "}
                <b>{licence.featuresOnlineNotes ? "Áno" : "Nie"}</b>
              </Typography>
              <Typography variant="h6">
                Automatické obnovovanie licencie:{" "}
                <b>{licence.autoRenewal ? "Áno" : "Nie"}</b>
              </Typography>
              {errorLicence && (
                <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                  {errorLicence}
                </Alert>
              )}
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end" }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={handleLicenceRenewal}
              >
                Aktualizuj licenciu
              </LoadingButton>
            </CardActions>
          </React.Fragment>
        ) : (
          <form onSubmit={handleLicenceSubmit}>
            <CardContent sx={{ minWidth: 400 }}>
              <Typography variant="h6">Zadaj licenčný klúč</Typography>
              <TextField
                sx={{ mt: 2 }}
                variant="outlined"
                label="Licenčný klúč"
                name="licenceNumber"
                id="licenceNumber"
                autoComplete="licenceNumber"
                fullWidth
                required
              />
              {errorLicence && (
                <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                  {errorLicence}
                </Alert>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <LoadingButton
                loading={loading}
                variant="contained"
                type="submit"
              >
                Over licenciu
              </LoadingButton>
            </CardActions>
          </form>
        )}
      </Card>
    </Box>
  );
  const tabPages = (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Nastavenia" {...a11yProps(0)} />
        <Tab label="Zmena hesla" {...a11yProps(1)} />
        <Tab label="Správa licencie" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {dataChangeElement}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {passwordChangeElement}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {licenceDetailElement}
      </TabPanel>
    </Box>
  );
  if (topPanelDirection) {
  }

  return (
    <Box sx={{ mt: 2, flexDirection: "column" }}>
      <Typography sx={{ mb: 2 }} variant="h4">
        Nastavenia používateľa
      </Typography>
      {tabPages}
    </Box>
  );
};

export default UserEditScreen;
