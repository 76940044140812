import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";

export default function EditPreddefNoteModal({
  values = {},
  isEdit,
  fetchNotes,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState("");

  const handleClickOpen = () => {
    setNoteContent(values.content);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchNotes();
  };

  const onSubmitHandler = async () => {
    try {
      let data = {
        name: noteContent,
      };
      let response;
      if (!values.noteId) {
        /// nova poznamka
        response = await notesApi.post("/api/pre_note", { ...data });
      } else {
        //uprava poznamky
        response = await notesApi.put("/api/pre_note/" + values.noteId, {
          ...data,
        });
      }
      if (response.status === 200) {
        handleClose();
      } else {
        alert("Nastala chyba, code status: " + response.status);
      }
    } catch (err) {
      alert("Nastala chyba: " + handelError(err));
    }
  };

  return (
    <span>
      {isEdit ? (
        <IconButton edge="end" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button sx={{ m: 0.5 }} variant="contained" onClick={handleClickOpen}>
          Pridaj poznámku
        </Button>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Nová preddefinovaná poznámku</DialogTitle>
          <DialogContent>
            <TextareaAutosize
              id="noteText"
              aria-label="minimum height"
              minRows={5}
              placeholder="Text poznámky"
              style={{ width: "100%" }}
              value={noteContent}
              onChange={(event) => {
                setNoteContent(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Zavri
            </Button>
            <Button variant="contained" onClick={onSubmitHandler}>
              Ulož
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
}
