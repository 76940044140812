import React from "react";
import Color from "color";

const TagBox = (props) => {
  return (
    <span
      style={{
        backgroundColor: props.tag.color,
        marginLeft: 5,
        marginTop: props.matches ? 0 : 4,
        paddingTop: 5,
        paddingBottom: 3,
        paddingLeft: 10,
        paddingRight: 10,
        color: Color(props.tag.color).isDark() ? "white" : "black",
        borderRadius: 10,
      }}
    >
      {props.tag.name}
    </span>
  );
};

export default TagBox;
