import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TagsList from "../components/Tags/TagsList";
import EditTag from "../components/Tags/EditTag";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import ConfirmModal from "../components/UI/ConfirmModal";
import notesApi from "../api/notesApi";

import { dataAction } from "../store/data-slice";
import { uiAction } from "../store/ui-slice";
import { handelError } from "../components/Helpers/helperFunctions";

const AllTags = (props) => {
  const [editTagVisible, setEditTagVisible] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  const dispatch = useDispatch();
  const tags = useSelector((state) => state.data.tagsData);
  const requestPending = useSelector((state) => state.ui.requestPending);

  const onShowTagForm = (obj) => {
    setEditTagVisible(obj);
  };
  const onEditTagCloseHandler = (val) => {
    setEditTagVisible(null);
    if (val) {
      dispatch(uiAction.setRequestPending(true));
      fetchTags();
    }
  };

  const onShowConfirmDialog = (obj) => {
    setConfirmModal(obj);
  };

  const onConfirmModalNoHandler = () => {
    setConfirmModal(null);
  };
  const onConfirmModalYesHandler = async (obj) => {
    let response;
    try {
      response = await notesApi.delete("/api/tag/" + obj.itemId);

      if (response.status === 200) {
        setConfirmModal(null);
        dispatch(uiAction.setRequestPending(true));
        fetchTags();
      } else {
        throw new Error("Nastala chyba pri mazani tagu: " + response.status);
      }
    } catch (err) {
      let mess = err.message;
      if (err.message.indexOf("500") !== -1) {
        mess = "Chyba pri mazani tagu - tag sa pravdepodobne používa";
      }
      alert(mess);
    }
  };

  const fetchTags = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/tag");
      response.data.sort((lh, rh) => {
        // tu potom poriadne poriesit zoradenie od najnovsej
        return rh.created_at > lh.created_at;
      });
      dispatch(dataAction.setTagsData(response.data));
    } catch (err) {
      alert("Chyba fetch notes: " + handelError(err));
    }
    dispatch(uiAction.setRequestPending(false));
  }, [dispatch]);

  let content = (
    <TagsList
      tagData={tags}
      showTagForm={onShowTagForm}
      showConfirmDialog={onShowConfirmDialog}
      fetchTags={fetchTags}
    />
  );

  useEffect(() => {
    dispatch(uiAction.setRequestPending(true));
    fetchTags();
  }, [fetchTags, dispatch]);

  if (requestPending) {
    content = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Fragment>
      {editTagVisible && (
        <EditTag item2edit={editTagVisible} onClose={onEditTagCloseHandler} />
      )}
      {confirmModal && (
        <ConfirmModal
          data={confirmModal}
          onNo={onConfirmModalNoHandler}
          onYes={onConfirmModalYesHandler}
        />
      )}
      {content}
    </Fragment>
  );
};

export default AllTags;
