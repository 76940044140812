import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import {
  Card,
  Box,
  TextField,
  Container,
  Button,
  Link,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";

import notesApi from "../api/notesApi";
import { authAction } from "../store/auth-slice";
import { handelError } from "../components/Helpers/helperFunctions";

const Signin = () => {
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(handelError(err));
    }
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      const response = await notesApi.post("/public/signin", {
        email: event.target.email.value,
        password: event.target.password.value,
      });
      dispatch(authAction.setToken(response.data.token));
      getCurrentUser();
      history.replace("/objects");
    } catch (err) {
      setError(handelError(err));
      setTimeout(() => {
        setError(null);
      }, 2000);
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "20%", mb: "20%" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            justifyItems: "center",
            m: 3,
          }}
        >
          <Typography component="h1" variant="h4 " sx={{ mb: 2 }}>
            Prihlásenie
          </Typography>
          <TextField
            variant="outlined"
            label="Email"
            name="email"
            id="email"
            autoComplete="email"
            fullWidth
            required
          />
          <Typography variant="h4" sx={{ mb: 2 }}></Typography>
          <TextField
            required
            type="password"
            id="password"
            autoComplete="current-password"
            variant="outlined"
            label="Heslo"
            fullWidth
          />
          {error && (
            <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
              {error}
            </Alert>
          )}
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
            Prihlásiť sa
          </Button>
          <Box
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {/* <Link component={RouterLink} to="/signup" variant="body2">
              Resetuj heslo
            </Link> */}
            <Link component={RouterLink} to="/signup" variant="body2">
              Registruj sa
            </Link>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default Signin;
