import React from "react";
import { ListItemText, ListItem, Button } from "@mui/material";
import EditLicenceModal from "./EditLicenceModal";

const LicenceItem = ({ licence, load, increasRenewal }) => {
  let secondaryText = `Osoba: ${licence.contactPerson}, 
  Ostava počet aktivácii: ${licence.activationRequestLeft},
  Ostava počet aktivácii desktop: ${licence.activationPdfRequestLeft},
  Ostava počet obnovení: ${licence.renewalRequestLeft}`;
  if (licence.activeUser.length) {
    secondaryText += `, Pripojené účty: ${licence.activeUser.join(", ")}`;
  }
  return (
    <ListItem divider>
      <ListItemText
        primary={`${licence.licenceNumber}`}
        secondary={secondaryText}
      />
      <Button
        variant="contained"
        onClick={() => {
          increasRenewal(licence.licenceNumber);
        }}
      >
        Navýš obnovenia
      </Button>
      <EditLicenceModal isEdit={true} load={load} values={licence} />
    </ListItem>
  );
};

export default LicenceItem;
