import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotesList from "../components/Notes/NotesList";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import { dataAction } from "../store/data-slice";
import { uiAction } from "../store/ui-slice";
import notesApi from "../api/notesApi";
import ConfirmModal from "../components/UI/ConfirmModal";
import { handelError } from "../components/Helpers/helperFunctions";
import { Card, Typography } from "@mui/material";

const AllNotes = (props) => {
  const objectData = useSelector((state) => state.data.objectData);
  const userData = useSelector((state) => state.auth.userData);
  const requestPending = useSelector((state) => state.ui.requestPending);
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(null);

  const fetchNotes = useCallback(async () => {
    dispatch(uiAction.setRequestPending(true));
    try {
      const response = await notesApi.get("/api/note");
      const tags = await notesApi.get("/api/tag");
      for (let record of response.data) {
        for (let o of objectData) {
          if (o.id === record.object_id) {
            record.object_name = o.name;
            break;
          }
        }
        let allTags = [];
        for (let noteTag of record.tags) {
          for (let tag of tags.data) {
            if (noteTag === tag.id) {
              allTags.push(tag);
              break;
            }
          }
        }
        record.tags = allTags;
      }
      dispatch(dataAction.setTagsData(tags.data));
      dispatch(dataAction.setNotesData(response.data));
    } catch (err) {
      alert("Chyba fetch notes: " + handelError(err));
    }

    dispatch(uiAction.setRequestPending(false));
  }, [dispatch, objectData]);

  const onShowConfirmModalHandler = (obj) => {
    setConfirmModal(obj);
  };
  const onConfirmModalNoHandler = () => {
    setConfirmModal(null);
  };
  const onConfirmModalYesHandler = async (obj) => {
    let response;
    response = await notesApi.delete("/api/note/" + obj.itemId);

    if (response.status === 200) {
      setConfirmModal(null);
      await fetchNotes();
    } else {
      alert("Nastala chyba pri mazani poznamky: " + response.status);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  let content = (
    <NotesList
      // notesData={notes}
      showConfirmDialog={onShowConfirmModalHandler}
      fetchNotes={fetchNotes}
    />
  );
  if (userData) {
    if (userData.isActive && !userData.licenceActive) {
      content = (
        <div className="centered">
          <Card>
            <Typography p={2} variant="h6">
              Platnosť licencie vypršala. Pre pokračovanie je potrebné obnoviť
              licenciu.
            </Typography>
          </Card>
        </div>
      );
    }
  }
  if (requestPending) {
    content = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Fragment>
      {confirmModal && (
        <ConfirmModal
          data={confirmModal}
          onNo={onConfirmModalNoHandler}
          onYes={onConfirmModalYesHandler}
        />
      )}

      {content}
    </Fragment>
  );
};

export default AllNotes;

// const t0 = performance.now();
//     const t1 = performance.now()
//     console.log("Call to doSomething took " + (t1 - t0) + " milliseconds.")
