import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery,
  List,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import NoteItem from "./NoteItem";

import _ from "lodash";
import ConfirmDialog from "../UI/ConfirmDialog";

import notesApi from "../../api/notesApi";
import EditNoteModal from "./EditNoteModal";
import DateFilterModal from "./DateFilterModal";
import ExportModalDialog from "./ExportModalDialog";
import { uiAction } from "../../store/ui-slice";
import { formatDate, replaceDiacritics } from "../Helpers/helperFunctions";
import TagPickerModal from "./TagPickerModal";

const NotesList = ({ fetchNotes }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useDispatch();
  const { searchText, sortOrder, tagFilter, dateFilter } = useSelector(
    (state) => state.ui.notesPageState
  );

  const notesData = useSelector((state) => state.data.notesData);
  const tagsData = useSelector((state) => state.data.tagsData);
  const [selectedTags, setSelectedTags] = React.useState([]);

  const [openDeleteHandler, setOpenDeleteHandler] = React.useState(false);
  const [noteId, setNodeId] = React.useState(-1);

  const [openEditNoteFormular, setOpenEditNoteFormular] = React.useState(false);
  const [isEditNoteFormular, setIsEditNoteFormular] = React.useState(true);
  const [selectedObjData, setSelectedObjData] = React.useState(null);

  // const [timeFilter, setTimeFilter] = useState({
  //   from: new Date(new Date().getFullYear(), 0, 1).getTime(),
  //   to: new Date().getTime() + 1000 * 3600 * 24,
  // });

  let filteredNotes = notesData.filter((note) => {
    let result = true;
    for (const tf of tagFilter) {
      result = false;
      for (let tag of note.tags) {
        if (tag.id === tf.id) {
          result = true;
          break;
        }
      }
      if (result) {
        break;
      }
    }

    if (!result) {
      return result;
    }
    const unix = new Date(note.created_at).getTime();
    if (unix < dateFilter.from || unix > dateFilter.to) {
      return false;
    }

    return (
      (note.object_name
        ? replaceDiacritics(note.object_name).includes(
            replaceDiacritics(searchText)
          )
        : true) ||
      replaceDiacritics(note.content).includes(replaceDiacritics(searchText))
    );
  });

  let sortedNotes = _.orderBy(filteredNotes, (note) => note.scan_at, [
    sortOrder ? "desc" : "asc",
  ]);

  const onDateFilterHandler = (dateData) => {
    dispatch(
      uiAction.setNotesPageDateFilter({
        from: dateData.from,
        to: dateData.to,
      })
    );
  };

  const onEditNoteHandler = (noteId) => {
    let objData = null;
    for (const note of sortedNotes) {
      if (note.id === noteId) {
        objData = {
          notes: [note],
          note2edit: noteId,
          name: note.object_name,
          id: note.object_id,
        };
      }
    }
    setIsEditNoteFormular(true);
    setSelectedObjData(objData);
    setOpenEditNoteFormular(true);
  };
  const handleCloseEditNoteFormular = (reload) => {
    if (reload) {
      setTimeout(() => {
        fetchNotes();
      }, 200);
    }
    setSelectedObjData(null);
    setOpenEditNoteFormular(false);
  };

  const onSortToogleHandler = (event) => {
    event.preventDefault();
    dispatch(uiAction.setNotesPageSortOrder(!sortOrder));
  };

  const handleCloseDeleteHandler = () => {
    setOpenDeleteHandler(false);
  };

  const onNoteDeleteHandler = (id) => {
    setNodeId(id);
    setOpenDeleteHandler(true);
  };

  const confirmAgreeHandler = async () => {
    const response = await notesApi.delete("/api/note/" + noteId);

    if (response.status === 200) {
      setNodeId(-1);
      fetchNotes();
    } else {
      alert("Nastala chyba pri mazani poznamky: " + response.status);
    }
  };

  let notesContent = (
    <List>
      {sortedNotes.map((note) => {
        return (
          <NoteItem
            key={note.id}
            id={note.id}
            isPreddef={false}
            created_at={note.scan_at}
            content={note.content}
            object_name={note.object_name}
            duration={note.duration}
            person_count={note.person_count}
            tags={note.tags}
            editHandler={onEditNoteHandler}
            deleteHandler={onNoteDeleteHandler}
            matches={matches}
          />
        );
      })}
    </List>
  );
  if (sortedNotes.length === 0) {
    notesContent = (
      <p className="centered">Nenašiel som žiadne vyhovujúce poznamky!</p>
    );
  }

  let tagSeletor = (
    <TextField
      sx={{ minWidth: 200 }}
      margin="dense"
      id="noteDuration"
      name="noteDuration"
      label="Filter podľa tagu"
      select
      fullWidth={!matches}
      value={tagFilter}
      onChange={(item) => {
        dispatch(uiAction.setNotesPageTagFilter(item.target.value));
      }}
    >
      <MenuItem value={-1}>Neaplikované</MenuItem>
      {tagsData.map((tag) => {
        return (
          <MenuItem value={tag.id} key={tag.id}>
            {tag.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
  tagSeletor = (
    <TagPickerModal
      selectedTags={selectedTags}
      tagsSubmitted={(tags) => {
        setSelectedTags(tags);
        dispatch(uiAction.setNotesPageTagFilter(tags));
      }}
      addingNote={false}
    />
  );

  return (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ ml: 1, pt: 1 }}>
            Poznámky
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              value={searchText}
              onChange={(event) => {
                dispatch(uiAction.setNotesPageSearchText(event.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        dispatch(uiAction.setNotesPageSearchText(""))
                      }
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => {
                setIsEditNoteFormular(false);
                setSelectedObjData({ chooseObject: true });
                setOpenEditNoteFormular(true);
              }}
            >
              Pridaj poznámku
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            mr: 1,
            ml: 1,
            flexDirection: matches ? "row" : "column",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            fullWidth={!matches}
            data-testid="toogleSort"
            onClick={onSortToogleHandler}
            sx={{ m: 0.5 }}
          >
            {!sortOrder ? "Od najnovších" : "Od najstarších"}
          </Button>
          {tagSeletor}
          <DateFilterModal
            matches={matches}
            callback={onDateFilterHandler}
            values={dateFilter}
          />
          <Typography>{`(${formatDate(dateFilter.from, false)} - ${formatDate(
            dateFilter.to,
            false
          )})`}</Typography>
          <ExportModalDialog matches={matches} data={sortedNotes} />
        </Box>
        <CardContent>{notesContent}</CardContent>
      </Card>
      <EditNoteModal
        open={openEditNoteFormular}
        handleClose={handleCloseEditNoteFormular}
        values={selectedObjData}
        matches={matches}
        isEdit={isEditNoteFormular}
        fetchNotes={fetchNotes}
      />
      <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete zmazať poznánku?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      />
    </Box>
  );
};

export default NotesList;
