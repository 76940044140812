import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { Box, IconButton, ListItem, Typography } from "@mui/material";

const TagItem = ({ name, color, id, editHandler, deleteHandler, matches }) => {
  return (
    <ListItem sx={{ justifyContent: "space-between" }}>
      <Typography variant="h6">{name}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: color,
            height: 30,
            width: 30,
            borderRadius: 2,
            mr: matches ? 5 : 1,
          }}
        ></Box>
        <IconButton
          edge="end"
          onClick={() => {
            editHandler(id);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          edge="end"
          onClick={() => {
            deleteHandler(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};
export default TagItem;
