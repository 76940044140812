import {
  ListItemText,
  ListItem,
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";
import DeleteConfirmation from "./DeleteConfirmation";

const UserComponent = ({ user, load }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = async () => {
    if (user.isActive) {
      await notesApi.put("/api/admin/user/" + user.id, {
        isActive: 0,
        licenceNumber: "undefined",
      });
      load();
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setError(null);
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    if (user.email === "galo") {
      return;
    }
    try {
      const response = await notesApi.put("/api/admin/user/" + user.id, {
        isActive: user.isActive ? 0 : 1,
        licenceNumber: event.target.licenceNumber.value,
      });

      if (response.status === 200) {
        handleClose();
        load();
      } else {
        throw new Error(response.status);
      }
    } catch (err) {
      setError("Chyba pri sprave licencie: " + handelError(err));
    }
  };
  let secondaryText = user.isActive ? "Aktívny" : "Neaktívny";
  if (user.isActive) {
    secondaryText += " - číslo licencie: " + user.licenceNumber;
    secondaryText += `, Platnosť: ${
      user.expire === 0 ? " neobmedzene" : "do: " + user.expireHuman
    }`;
  }
  return (
    <React.Fragment>
      <ListItem divider>
        <ListItemText primary={`${user.email}`} secondary={secondaryText} />
        <Button variant="contained" onClick={handleClickOpen}>
          {user.isActive ? "Deaktivuj" : "Priraď licenciu"}
        </Button>
        <DeleteConfirmation load={load} user={user} />
      </ListItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Zadaj licenčný klúč</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent sx={{ minWidth: 400 }}>
            <TextField
              sx={{ mt: 2 }}
              variant="outlined"
              label="Licenčný klúč"
              name="licenceNumber"
              id="licenceNumber"
              autoComplete="licenceNumber"
              fullWidth
              required
            />
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zruš
            </Button>
            <Button type="submit">Priraď</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default UserComponent;
