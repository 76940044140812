import React from "react";

import classes from "./Button.module.css";

const Button = (props) => {
  return (
    <button
      data-testid={props["data-testid"]}
      onClick={props.onClick}
      className={`${classes.button} ${props.className}`}
      type={props.type | "button"}
      disabled={props.disabled | false}
    >
      {props.label}
    </button>
  );
};

export default Button;
