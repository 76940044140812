import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import skLocale from "date-fns/locale/sk";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function DateFilterModal({
  values,
  matches,
  callback,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [dateFromValue, setDateFromValue] = React.useState(new Date());
  const [dateToValue, setDateToValue] = React.useState(new Date());

  const handleClickOpen = () => {
    setDateFromValue(new Date(values.from));
    setDateToValue(new Date(values.to));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleReset = () => {
    const from = new Date();
    const to = new Date();
    from.setDate(from.getDate() + 1);
    setDateToValue(from);
    to.setDate(to.getDate() - 91);
    setDateFromValue(to);
  };

  const pickerFrom = (
    <Box>
      <Typography>Dátum od:</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
        <DatePicker
          label="Dátum od"
          value={dateFromValue}
          onChange={(newValue) => {
            setDateFromValue(newValue);
          }}
          renderInput={(params) => (
            <TextField id="noteDate" {...params} sx={{ mt: 2 }} fullWidth />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
  const pickerTo = (
    <Box>
      <Typography>Dátum do</Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
        <DatePicker
          label="Dátum do"
          value={dateToValue}
          onChange={(newValue) => {
            setDateToValue(newValue);
          }}
          renderInput={(params) => (
            <TextField id="noteDate" {...params} sx={{ mt: 2 }} fullWidth />
          )}
        />
      </LocalizationProvider>
    </Box>
  );

  return (
    <Box sx={{ width: !matches ? "100%" : undefined }}>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        fullWidth={!matches}
        onClick={handleClickOpen}
      >
        Časový filter
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Časový filter</DialogTitle>
          <DialogContent>
            {pickerFrom}
            {pickerTo}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zavri
            </Button>
            <Button onClick={handleReset}>Resetuj filter</Button>
            <Button
              onClick={() => {
                callback({
                  from: new Date(dateFromValue).getTime(),
                  to: new Date(dateToValue).getTime(),
                });
                handleClose();
              }}
            >
              Potvrď
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
