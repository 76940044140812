import React, { Fragment, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";

import InfoModal from "../UI/InfoModal";
import Button from "../UI/Button";
import DurationSelector from "../UI/DurationSelector";

import classes from "./NoteForm.module.css";
import PreddefPicker from "./PreddefPicker";

import { countOccurence } from "../Helpers/helperFunctions";

let mouseDeleter = {
  timeoutId: -1,
  executed: false,
};

const NoteForm = (props) => {
  const dateRef = useRef();
  const textInputRef = useRef();
  const preddefNoteRef = useRef();
  const durationSelectRef = useRef();
  const personCountRef = useRef();
  const [durationText, setDurationText] = useState("");
  const [durationValid, setDurationValid] = useState(-1);

  const [showModal, setShowModal] = useState(null);
  const [touched, setTouched] = useState(false);
  const [showPreddefPicker, setShowPreddefPicker] = useState(false);

  let { note2edit } = props;

  const onSubmitFormHandler = async (event) => {
    event.preventDefault();
    let data = {};
    if (note2edit) {
      data = {
        ...note2edit,
        content: textInputRef.current.value,
        scan_at: new Date(dateRef.current.value),
        person_count: parseInt(personCountRef.current.value),
        duration: durationText !== "" ? durationValid : 0,
      };
    } else {
      data = {
        content: textInputRef.current.value,
        scan_at: new Date(dateRef.current.value),
        duration: durationText !== "" ? durationValid : 0,
        object_id: -1,
        def: preddefNoteRef.current.checked,
        person_count: parseInt(personCountRef.current.value),
      };
    }

    if (durationValid === -1) {
      setShowModal({
        title: "Upozornenie",
        question: "Neplatná hodnota trvania",
      });
      return;
    }
    if (data.content.length < 3) {
      setShowModal({
        title: "Upozornenie",
        question: "Minimálna dĺžka poznámky sú 4 znaky",
      });
      return;
    }
    setTouched(false);

    props.onSubmitFormHandler(data);
  };
  const onFocusHandler = () => {
    setTouched(true);
  };

  const onOkModalHandler = () => {
    setShowModal(null);
  };

  const onHidePickerHandler = (content) => {
    if (content) {
      textInputRef.current.value = content;
      setTouched(true);
    }
    setShowPreddefPicker(false);
  };

  const onShowTagPicker = (event) => {
    event.preventDefault();
    props.showTagPicker();
  };

  const parseDuration = (value) => {
    const parts = value.split(" ");
    let duration = -1;
    let reD = new RegExp("^[0-9]{1,2}d$");
    let reH = new RegExp("^[0-9]{1,3}h$");
    let reM = new RegExp("^[0-9]{1,3}m$");

    if (
      countOccurence(value, "m") > 1 ||
      countOccurence(value, "h") > 1 ||
      countOccurence(value, "d") > 1
    ) {
      return -1;
    }

    for (let part of parts) {
      if (part !== "") {
        let part_valid = false;
        let add = 0;
        if (reD.test(part)) {
          add = parseInt(part.substr(0, part.indexOf("d"))) * 1440;
          part_valid = true;
        }
        if (reH.test(part)) {
          add = parseInt(part.substr(0, part.indexOf("h"))) * 60;
          part_valid = true;
        }
        if (reM.test(part)) {
          add = parseInt(part.substr(0, part.indexOf("m")));
          part_valid = true;
        }
        if (part_valid) {
          if (duration === -1) {
            duration = 0;
          }
          duration += add;
        } else {
          duration = -1;
          break;
        }
      }
    }
    return duration;
  };

  const durationChangeHandler = (event) => {
    setDurationValid(parseDuration(event.target.value));
    setDurationText(event.target.value);
  };

  const translateDuration = (note2edit) => {
    let duration_min = 0;
    if (note2edit) {
      duration_min = note2edit.duration;
    }
    let res = "";
    if (duration_min / 1440 > 1) {
      res = Math.floor(duration_min / 1440) + "d ";
      duration_min = duration_min % 1440;
    }
    if (duration_min / 60 > 1) {
      res += Math.floor(duration_min / 60) + "h ";
      duration_min = duration_min % 60;
    }
    if (duration_min > 0) {
      res += duration_min + "m";
    }
    return res;
  };

  let dateInput = (
    <div className={classes.lineControl}>
      <label htmlFor="date">Dátum</label>
      <input type="date" id="date" ref={dateRef} onFocus={onFocusHandler} />
    </div>
  );
  let contentIntput = (
    <div className={classes.control}>
      <label htmlFor="text">Text poznámky</label>
      <textarea id="text" rows="8" ref={textInputRef}></textarea>
    </div>
  );

  let durationSelector = (
    <DurationSelector
      className={classes.lineControl}
      label="Zadať trvanie"
      refDuration={durationSelectRef}
    />
  );

  durationSelector = (
    <div className={classes.lineControl}>
      <label htmlFor="durationInput">Zadaj trvanie</label>
      <input
        id="durationInput"
        type="text"
        placeholder="1d 12h 30m"
        value={durationText}
        onChange={durationChangeHandler}
        style={{ color: durationValid !== -1 ? "black" : "red" }}
      />
    </div>
  );

  let personCountContent = (
    <div className={classes.lineControl}>
      <label>Zadaj počet osôb</label>
      <input
        type="number"
        id="quantity"
        name="quantity"
        min="1"
        max="10"
        defaultValue={1}
        ref={personCountRef}
        style={{ width: 80 }}
      />
    </div>
  );

  let tagsContent = (
    <Fragment>
      {props.tags.map((tag) => {
        return (
          <span
            style={{ color: tag.color }}
            key={tag.id}
            className={classes.tagSpan}
            onMouseDown={() => {
              mouseDeleter.timeoutId = setTimeout(() => {
                props.removetag(tag.id);
                mouseDeleter.executed = true;
              }, 1000);
              mouseDeleter.executed = false;
            }}
            onMouseLeave={() => {
              if (!mouseDeleter.executed) {
                clearInterval(mouseDeleter.timeoutId);
              }
            }}
          >
            {tag.name}
          </span>
        );
      })}
    </Fragment>
  );

  let tagPicker = (
    <div className={classes.lineControl}>
      <label>
        Tagy:
        {tagsContent}
      </label>
      <Button type="button" label="Vyber" onClick={onShowTagPicker} />
    </div>
  );

  let preddefSwitch = (
    <div className={classes.lineControl}>
      <label htmlFor="savePreddef">Uložiť ako predvolený text</label>
      <label className={classes.switch}>
        <input type="checkbox" id="savePreddef" ref={preddefNoteRef} />
        <span className={classes.slider}></span>
      </label>
    </div>
  );

  let controls = (
    <div className={classes.actions}>
      <Button
        type="button"
        label={"Späť"}
        onClick={(event) => {
          event.preventDefault();
          props.onClose();
        }}
      />
      <Button
        type="button"
        label={"Vyber z predvolených"}
        onClick={(event) => {
          event.preventDefault();
          setShowPreddefPicker(true);
        }}
      />
      <Button
        type="button"
        label={note2edit ? "Uprav poznámku" : "Ulož poznámku"}
        onClick={onSubmitFormHandler}
      />
    </div>
  );

  useEffect(() => {
    if (note2edit) {
      textInputRef.current.value = note2edit.content;
      dateRef.current.value = new Date(note2edit.scan_at)
        .toISOString()
        .substr(0, 10);
      personCountRef.current.value = note2edit.person_count;
      setDurationText(translateDuration(note2edit));
      setDurationValid(parseDuration(translateDuration(note2edit)));
    } else {
      dateRef.current.value = new Date().toISOString().substr(0, 10);
    }
    setTouched(false);
  }, [note2edit, props.tags]);

  return (
    <Fragment>
      <Prompt
        when={touched}
        message={(location) =>
          "Naozaj chceš odísť a stratiť všetky neuložené údaje?"
        }
      />
      {showModal && <InfoModal data={showModal} onOk={onOkModalHandler} />}
      {showPreddefPicker && <PreddefPicker onHide={onHidePickerHandler} />}
      <div className={classes.card}>
        <h2 style={{ textAlign: "center" }}>
          {props.isNewNote ? "Nová poznámka" : "Úprava poznámky"}
        </h2>
        <h4>Objekt: {props.object_name}</h4>
        <form onFocus={onFocusHandler}>
          {dateInput}
          {contentIntput}
          {durationSelector}
          {personCountContent}
          {tagPicker}
          {props.isNewNote && preddefSwitch}
        </form>
        {controls}
      </div>
    </Fragment>
  );
};

export default NoteForm;
