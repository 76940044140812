import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import "./App.css";
import Layout from "./components/Layout/Layout";
import Login from "./pages/Login";
import AllObjects from "./pages/AllObjects";
import AllNotes from "./pages/AllNotes";
import PreddefNotes from "./pages/PreddefNotes";
import NoteEdit from "./pages/NoteEdit";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/Helpers/ProtectedRoute";
import { useDispatch } from "react-redux";
import { authAction } from "./store/auth-slice";
import SignUp from "./pages/SignUp";
import AllTags from "./pages/AllTags";
import AdminPage from "./pages/AdminPage";
import SettingsPage from "./pages/SettingsPage";
import UserEditScreen from "./pages/UserEditScreen";
import { handelError } from "./components/Helpers/helperFunctions";

import notesApi from "./api/notesApi";
import LicencesPage from "./pages/LicencesPage";
import TrashPage from "./pages/TrashPage";

function App() {
  const dispatch = useDispatch();
  const history = useHistory();

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(handelError(err));
    }
  }, [dispatch]);

  const checkCurrentUser = useCallback(async () => {
    let lastChecked = localStorage.getItem("lastChecked");
    const token = localStorage.getItem("qrAppToken");
    if (lastChecked && token) {
      if (new Date().getTime() > lastChecked + 24 * 3600 * 1000) {
        getCurrentUser();
      }
    }
  }, [getCurrentUser]);

  useEffect(() => {
    let token = localStorage.getItem("qrAppToken");
    if (token) {
      dispatch(authAction.setToken(token));
      getCurrentUser();
      history.replace("/objects");
    }
  }, [dispatch, history, getCurrentUser]);

  useEffect(() => {
    window.addEventListener("focus", checkCurrentUser);
    return () => {
      window.removeEventListener("focus", checkCurrentUser);
    };
  }, [checkCurrentUser]);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <ProtectedRoute path="/admin/licences" component={LicencesPage} />
        <ProtectedRoute path="/admin" component={AdminPage} />
        <ProtectedRoute path="/objects" component={AllObjects} />
        <ProtectedRoute path="/notes" exact component={AllNotes} />
        <ProtectedRoute path="/notes/:noteId" component={NoteEdit} />
        <ProtectedRoute path="/preddefNotes" component={PreddefNotes} />
        <ProtectedRoute path="/tags" component={AllTags} />
        <ProtectedRoute path="/trash" component={TrashPage} />
        <ProtectedRoute path="/settings" component={SettingsPage} />
        <ProtectedRoute path="/user/:userId" component={UserEditScreen} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
