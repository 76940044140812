import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  TextareaAutosize,
  MenuItem,
  Switch,
  Alert,
  Typography,
  Autocomplete,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import skLocale from "date-fns/locale/sk";
import PreddefPickerModal from "./PreddefPickerModal";
import TagPickerModal from "./TagPickerModal";
import { useSelector } from "react-redux";
import { handelError } from "../Helpers/helperFunctions";

export default function EditNoteModal({
  values = {},
  isEdit = false,
  open,
  openHandler,
  handleClose,
  matches,
  showButton = false,
  ...rest
}) {
  const objects = useSelector((state) => state.data.objectData);

  const [error, setError] = React.useState(null);
  const [dateValue, setDateValue] = React.useState(new Date());
  const [peopleCount, setPeopleCount] = React.useState(1);
  const [noteContent, setNoteContent] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [duration, setDuration] = React.useState(60);

  const handlePick = (preddef) => {
    setNoteContent(preddef.name);
  };

  const completeOptions = objects.map((o) => {
    return o.name;
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let object_id = values.id;
    if (values.chooseObject) {
      for (const o of objects) {
        if (o.name === event.target.objectAutocomplet.value) {
          object_id = o.id;
        }
      }
    }
    if (!object_id) {
      setError("Chyba pri vybere objektu");
      return;
    }
    if (event.target.noteText.value.length < 3) {
      setError("Minimálna dĺžka poznámky sú 3 znaky.");
      return;
    }

    try {
      if (event.target.notePreddef.checked) {
        await notesApi.post("/api/pre_note", {
          name: event.target.noteText.value,
        });
      }
      const dateArr = event.target.noteDate.value.split(".");
      if (dateArr.length !== 3) {
        setError("Chyba výberu dátumu");
        return;
      }

      let note2save = {
        content: event.target.noteText.value,
        scan_at: new Date(
          parseInt(dateArr[2]),
          parseInt(dateArr[1] - 1),
          parseInt(dateArr[0]),
          5,
          0,
          0
        ),
        duration: event.target.noteDuration.value,
        object_id: object_id,
        def: event.target.notePreddef.checked,
        person_count: parseInt(event.target.notePeopleCount.value),
        tags: [],
      };
      for (let tag of selectedTags) {
        note2save.tags.push(tag.id);
      }
      let resp = "";

      if (values.note2edit) {
        let api_url = "/api/note/" + values.note2edit;
        let resp = "";

        resp = await notesApi.put(api_url, { ...note2save });
        if (resp.status === 200) {
          setError(null);
          setSelectedTags([]);
          handleClose(true);
        } else {
          alert("Nastala chyba, code status: " + resp.status);
        }
      } else {
        resp = await notesApi.post("/api/note", { ...note2save });
        if (resp.status === 200) {
          setError(null);
          setSelectedTags([]);
          handleClose(true);
        } else {
          setError("Nastala chyba, code status: " + resp.status);
        }
      }
    } catch (err) {
      setError("Chyba pri ukladani poznamky: " + handelError(err));
    }
  };

  let content = null;
  if (values) {
    content = (
      <Box>
        {values.chooseObject ? (
          <Autocomplete
            disablePortal
            id="objectAutocomplet"
            options={completeOptions}
            sx={{ mt: 1 }}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Objekt" />}
          />
        ) : (
          <Typography>Objekt: {values.name}</Typography>
        )}
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={skLocale}>
          <DatePicker
            label="Dátum"
            value={dateValue}
            onChange={(newValue) => {
              setDateValue(newValue);
            }}
            renderInput={(params) => (
              <TextField id="noteDate" {...params} sx={{ mt: 2 }} fullWidth />
            )}
          />
        </LocalizationProvider>
        <Typography sx={{ pt: 1, pb: 1 }}>Text poznámky</Typography>
        <TextareaAutosize
          id="noteText"
          aria-label="minimum height"
          minRows={5}
          placeholder="Text poznámky"
          style={{ width: "100%" }}
          value={noteContent}
          onChange={(event) => {
            setNoteContent(event.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="noteDuration"
          name="noteDuration"
          label="Trvanie"
          fullWidth
          select
          value={duration}
          onChange={(event) => {
            setDuration(event.target.value);
          }}
        >
          <MenuItem value={0}>Nezadávam</MenuItem>
          <MenuItem value={30}>Pol hodina</MenuItem>
          <MenuItem value={60}>1 hodina</MenuItem>
          <MenuItem value={120}>2 hodiny</MenuItem>
          <MenuItem value={180}>3 hodiny</MenuItem>
          <MenuItem value={240}>4 hodiny</MenuItem>
          <MenuItem value={360}>6 hodin</MenuItem>
          <MenuItem value={480}>8 hodin</MenuItem>
        </TextField>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          id="notePeopleCount"
          label="Počet ľudí"
          type="number"
          value={peopleCount}
          onChange={(event) => {
            setPeopleCount(event.target.value);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 0.5,
            flexDirection: matches ? "row" : "column",
          }}
        >
          <Typography sx={{ alignSelf: "center" }}>Tagy:</Typography>
          {selectedTags.map((tag) => {
            return (
              <Box
                sx={{
                  bgcolor: tag.color,
                  borderRadius: 2,
                  alignSelf: matches ? "center" : undefined,
                  m: 0.5,
                }}
                key={tag.name}
              >
                <Typography align="center" p={0.4} color="white">
                  {tag.name}
                </Typography>
              </Box>
            );
          })}
          <TagPickerModal
            selectedTags={selectedTags}
            tagsSubmitted={setSelectedTags}
            addingNote={true}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ alignSelf: "center" }}>
            Uložiť ako predvolený text
          </Typography>
          <Switch id="notePreddef" />
        </Box>
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
      </Box>
    );
  }

  React.useEffect(() => {
    setError(null);
    setDateValue(new Date());
    setPeopleCount(1);
    setNoteContent("");
    setSelectedTags([]);
    setDuration(60);
    if (values) {
      if (values.note2edit) {
        for (const note of values.notes) {
          if (note.id === values.note2edit) {
            setDateValue(new Date(note.scan_at));
            setPeopleCount(note.person_count);
            setNoteContent(note.content);
            setSelectedTags(note.tags);
            setDuration(note.duration);
          }
        }
      }
    }
  }, [open, values]);

  const dialogOpen = open && content !== null;

  return (
    <div>
      {showButton && (
        <Button
          onClick={openHandler}
          variant="contained"
          sx={{ m: 0.5 }}
          fullWidth={!matches}
        >
          Pridaj poznámku
        </Button>
      )}
      <Dialog open={dialogOpen} onClose={() => handleClose(false)}>
        <DialogContent sx={{ p: matches ? 5 : 1.5 }}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              {isEdit ? "Uprav poznámku" : "Pridaj poznámku"}
            </DialogTitle>
            <DialogContent sx={{ p: 0 }}>{content}</DialogContent>
            <DialogActions
              sx={{
                flexDirection: matches ? "row" : "column",
              }}
            >
              <Button
                onClick={() => handleClose(false)}
                color="error"
                variant="contained"
                fullWidth={!matches}
                sx={{ mb: 0.5, ml: 0.8 }}
              >
                Zruš
              </Button>
              <PreddefPickerModal matches={matches} handlePick={handlePick} />
              <Button
                variant="contained"
                type="submit"
                fullWidth={!matches}
                sx={{ mb: 0.5 }}
              >
                Ulož
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
