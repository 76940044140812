import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  Box,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  List,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import notesApi from "../api/notesApi";
import {
  handelError,
  normalizeStr,
} from "../components/Helpers/helperFunctions";
import EditLicenceModal from "../components/Helpers/EditLicenceModal";
import LicenceItem from "../components/Helpers/LicenceItem";

const LicencesPage = () => {
  const [licences, setLicences] = React.useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [searchText, setSearchText] = useState("");

  const load = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/admin/licence");
      setLicences(response.data);
    } catch (error) {
      alert(handelError(error));
    }
  }, []);

  const increasRenewal = async (number) => {
    try {
      await notesApi.put("/api/admin/increaseRenewal", { number });
      load();
    } catch (error) {
      alert(handelError(error));
    }
  };

  const filteredLicences = licences.filter((licence) => {
    const sString = `${normalizeStr(licence.licenceNumber)} 
    ${normalizeStr(licence.contactPerson)} 
    ${normalizeStr(licence.contactPersonEmail)} `;
    return sString.includes(normalizeStr(searchText));
  });

  let content = (
    <List>
      {filteredLicences.map((licence, index) => {
        return (
          <LicenceItem
            key={index}
            licence={licence}
            load={load}
            increasRenewal={increasRenewal}
          />
        );
      })}
    </List>
  );
  if (!filteredLicences.length) {
    content = (
      <p className="centered">Nenašiel som žiadne vyhovujúce licencie!</p>
    );
  }

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" sx={{ ml: 1, pt: 1 }}>
            Licencie
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <EditLicenceModal isEdit={false} load={load} />
          </Box>
        </Box>
        {content}
      </Card>
      {/* <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete zmazať poznánku?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      /> */}
    </Box>
  );
};

export default LicencesPage;
