import React, { Fragment } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import { useSelector } from "react-redux";

const Layout = (props) => {
  const userData = useSelector((state) => state.auth.userData);
  const history = useHistory();
  return (
    <Fragment>
      <MainNavigation></MainNavigation>
      {userData && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="h6" sx={{ pr: 2 }}>
            Používateľ: {userData.email}
          </Typography>
          {!userData.isActive && (
            <Typography variant="h6" sx={{ pr: 2 }} color="red">
              Účet nie je aktivovaný
            </Typography>
          )}
          <Button
            onClick={() => {
              history.push("/settings");
            }}
          >
            Nastavenia
          </Button>
          {userData.email === "galo" && (
            <Button
              onClick={() => {
                history.push("/admin");
              }}
            >
              Administrácia
            </Button>
          )}
          {userData.email === "galo" && (
            <Button
              onClick={() => {
                history.push("/admin/licences");
              }}
            >
              Správa licencií
            </Button>
          )}
        </Box>
      )}
      <Container>{props.children}</Container>
      <Box
        sx={{
          bgcolor: "#262626",
          bottom: 0,
          color: "#666666",
          display: "flex",
          justifyContent: "flex-end",
          position: "fixed",
          textTransform: "uppercase",
          width: "100%",
          pr: 2,
        }}
      >
        <Typography variant="body" sx={{ p: 0.5 }}>
          Evidencia výťahov {new Date().getFullYear()}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default Layout;
