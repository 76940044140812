import React, { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import notesApi from "../api/notesApi";
import { dataAction } from "../store/data-slice";
import { uiAction } from "../store/ui-slice";
import ObjectsList from "../components/Objects/ObjectsList";
import { handelError } from "../components/Helpers/helperFunctions";
import { Card, Typography } from "@mui/material";

const AllObjects = (props) => {
  const objectData = useSelector((state) => state.data.objectData);
  const requestPending = useSelector((state) => state.ui.requestPending);
  const userData = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();

  const objectDataLength = objectData.length;

  const fetchObjects = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/object");
      dispatch(dataAction.setObjectsData(response.data));
    } catch (err) {
      alert("Chyba fetch objects: " + handelError(err));
    }
    dispatch(uiAction.setRequestPending(false));
  }, [dispatch]);

  useEffect(() => {
    if (objectDataLength === 0) {
      dispatch(uiAction.setRequestPending(true));
      fetchObjects();
    }
  }, [fetchObjects, dispatch, objectDataLength]);

  let content = (
    <ObjectsList objData={objectData} fetchObjects={fetchObjects} />
  );

  if (userData) {
    if (userData.isActive && !userData.licenceActive) {
      content = (
        <div className="centered">
          <Card>
            <Typography p={2} variant="h6">
              Platnosť licencie vypršala. Pre pokračovanie je potrebné obnoviť
              licenciu.
            </Typography>
          </Card>
        </div>
      );
    }
  }

  if (requestPending) {
    content = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return content;
};

export default AllObjects;
