import React from "react";

//import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import EditPreddefNoteModal from "../PreddefNotes/EditPreddefNoteModal";

const PreddefNote = ({ id, content, deleteHandler, fetchNotes }) => {
  return (
    <ListItem divider sx={{ justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">{content}</Typography>
      </Box>
      <Box sx={{ minWidth: 70, minHeight: 50 }}>
        <EditPreddefNoteModal
          isEdit={true}
          values={{ noteId: id, content }}
          fetchNotes={fetchNotes}
        />
        <IconButton
          edge="end"
          onClick={() => {
            deleteHandler(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default PreddefNote;
