import React, { useCallback, useEffect } from "react";
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  InputAdornment,
  List,
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";

import ObjectItem from "./ObjectItem";
import notesApi from "../../api/notesApi";

import { uiAction } from "../..//store/ui-slice";
import { useTheme } from "@mui/material/styles";

import _ from "lodash";
import ObjectNotesModal from "./ObjectNotesModal";
import EditNoteModal from "../Notes/EditNoteModal";
import EditObjectModal from "./EditObjectModal";
import { handelError, replaceDiacritics } from "../Helpers/helperFunctions";

const ObjectsList = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const { searchText, selectedObjectId } = useSelector(
    (state) => state.ui.objectPageState
  );

  const [selectedObjData, setSelectedObjData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openAddNoteFormular, setOpenAddNoteFormular] = React.useState(false);

  const handleOpenAddNoteFormular = (objData) => {
    dispatch(uiAction.setObjectPageSelectedId(objData.id));
    setSelectedObjData({ ...objData, notes: [] });
    setOpenAddNoteFormular(true);
  };
  const handleCloseAddNoteFormular = (reload) => {
    setOpenAddNoteFormular(false);
    if (selectedObjData.note2edit || reload) {
      fetchNotes(selectedObjectId);
    }
  };

  const handlShowDetails = (id) => {
    dispatch(uiAction.setObjectPageSelectedId(id));
  };

  const editNoteHandler = (id) => {
    setSelectedObjData({ ...selectedObjData, note2edit: id });
    setOpenAddNoteFormular(true);
  };

  const fetchNotes = useCallback(
    async (objectId) => {
      setSelectedObjData(null);
      try {
        const tags = await notesApi.get("/api/tag");
        const response = await notesApi.get("/api/note/byobject/" + objectId);
        let sortedNotes = _.orderBy(response.data, (note) => note.scan_at, [
          "desc",
        ]);
        let newObj = null;
        for (let obj of props.objData) {
          if (obj.id === selectedObjectId) {
            newObj = {
              name: obj.name,
              description: obj.description,
            };
            break;
          }
        }
        for (let record of sortedNotes) {
          let allTags = [];
          for (let noteTag of record.tags) {
            for (let tag of tags.data) {
              if (noteTag === tag.id) {
                allTags.push(tag);
                break;
              }
            }
          }
          record.tags = allTags;
        }
        setSelectedObjData({
          ...newObj,
          notes: sortedNotes,
          id: selectedObjectId,
        });
      } catch (err) {
        alert("Chyba fetch notes: " + handelError(err));
      }
    },
    [selectedObjectId, props.objData]
  );

  let filteredObjects = props.objData.filter((obj) => {
    return (
      replaceDiacritics(obj.name).includes(replaceDiacritics(searchText)) ||
      replaceDiacritics(obj.description).includes(replaceDiacritics(searchText))
      // obj.name.toLowerCase().includes(searchText.toLowerCase()) ||
      // obj.description.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const onClickObjectHandler = (objectId) => {
    dispatch(uiAction.setObjectPageSelectedId(objectId));
    handleOpen();
  };

  let objContent = (
    <List>
      {filteredObjects.map((obj) => {
        return (
          <ObjectItem
            key={obj.id}
            id={obj.id}
            name={obj.name}
            description={obj.description}
            onClick={onClickObjectHandler}
            onAddNote={handleOpenAddNoteFormular}
            onShowDetails={handlShowDetails}
            isSelected={selectedObjectId === obj.id}
          />
        );
      })}
    </List>
  );
  if (filteredObjects.length === 0) {
    objContent = (
      <p className="centered">Nenašiel som žiadne vyhovujúce objekty!</p>
    );
  }

  useEffect(() => {
    if (selectedObjectId !== -1) {
      fetchNotes(selectedObjectId);
    }
  }, [fetchNotes, selectedObjectId]);

  return (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" sx={{ ml: 1, pt: 1 }}>
            Objekty
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              value={searchText}
              onChange={(event) => {
                dispatch(uiAction.setObjectPageSearchText(event.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        dispatch(uiAction.setObjectPageSearchText(""))
                      }
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <EditObjectModal
              matches={matches}
              fetchObjects={props.fetchObjects}
            />
          </Box>
        </Box>
        <CardContent>{objContent}</CardContent>
      </Card>
      <ObjectNotesModal
        open={open}
        handleClose={handleClose}
        objData={selectedObjData}
        fetchNotes={fetchNotes}
        fetchObjects={props.fetchObjects}
        editNoteHandler={editNoteHandler}
      />
      <EditNoteModal
        open={openAddNoteFormular}
        handleClose={handleCloseAddNoteFormular}
        values={selectedObjData}
        matches={matches}
      />
    </Box>
  );
};

export default ObjectsList;
