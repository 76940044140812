import React from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { handelError } from "./helperFunctions";
import api from "../../api/notesApi";

const EditUserData = ({ values, onEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.put("/api/user", {
        name: event.target.name.value,
      });
      if (response.status === 200) {
        if (onEdit) {
          onEdit();
        }
      }
      handleClose();
    } catch (error) {
      alert(handelError(error));
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Uprav údaje
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogTitle>Formulár úpravy údajov</DialogTitle>
            <DialogContent component="form" onSubmit={handleSubmit}>
              <TextField
                sx={{ mt: 2 }}
                variant="outlined"
                label="Meno a priezvisko"
                name="name"
                id="name"
                autoComplete="name"
                defaultValue={values.name || ""}
                fullWidth
                required
              />
              {error && (
                <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
                  {error}
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="error">
                Zruš
              </Button>
              <Button type="submit">Potvrď</Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditUserData;
