import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  Alert,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";

export default function EditTagModal({
  open,
  handleClose,
  fetchTags,
  matches,
  tagEdit,
  tags,
}) {
  const [tagName, setTagName] = React.useState("");
  const [color, setColor] = React.useState("#000000");
  const [error, setError] = React.useState(null);

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();
    if (tagName === "") {
      setError(`Je potrebný zadať názov tagu`);
      return;
    }
    for (const tag of tags) {
      if (tag.name === tagName) {
        setError(`Chyba! Tag s názvom: ${tagName} už existuje`);
        return;
      }
    }
    try {
      let response;
      let data = {
        name: tagName,
        color: color,
      };
      if (tagEdit) {
        response = await notesApi.put("/api/tag/" + tagEdit.id, {
          ...data,
        });
      } else {
        response = await notesApi.post("/api/tag", {
          ...data,
        });
      }
      if (response.status === 200) {
        setTagName("");
        fetchTags();
        handleClose(true);
      } else {
        throw new Error(response.status);
      }
    } catch (err) {
      alert("Chyba ukladania tag: " + handelError(err));
    }
  };

  const content = (
    <React.Fragment>
      <TextField
        sx={{ mt: 2 }}
        fullWidth
        id="tagName"
        label="Názov tagu"
        value={tagName}
        onChange={(event) => {
          setTagName(event.target.value);
        }}
      />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Vyber farbu</Typography>
        <input
          type="color"
          id="color-picker"
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
          }}
        />
      </Box>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (tagEdit) {
      setTagName(tagEdit.name);
      setColor(tagEdit.color);
    } else {
      setTagName("");
      setColor("#000000");
    }
  }, [tagEdit]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{tagEdit ? "Uprav tag" : "Pridaj tag"}</DialogTitle>
          <DialogContent>
            {content}
            {error && (
              <Alert sx={{ mt: 2 }} variant="filled" severity="error">
                {error}
              </Alert>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              flexDirection: matches ? "row" : "column",
            }}
          >
            <Button
              onClick={() => handleClose(false)}
              color="error"
              variant="contained"
              fullWidth={!matches}
              sx={{ mb: 0.5, ml: 0.8 }}
            >
              Zavri
            </Button>
            <Button
              variant="contained"
              type="submit"
              fullWidth={!matches}
              sx={{ mb: 0.5 }}
            >
              Ulož
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
