import React, { useCallback, useEffect } from "react";

import { Card, Container, List } from "@mui/material";
import notesApi from "../api/notesApi";
import UserComponent from "../components/admin/UserComponent";
import { handelError } from "../components/Helpers/helperFunctions";

const AdminPage = () => {
  const [users, setUsers] = React.useState([]);
  const load = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/admin/user");
      setUsers(response.data.filter((record) => record.email !== "galo"));
    } catch (error) {
      alert(handelError(error));
    }
  }, []);

  const content = (
    <List>
      {users.map((user) => {
        return <UserComponent user={user} key={user.id} load={load} />;
      })}
    </List>
  );

  useEffect(() => {
    load();
  }, [load]);
  return (
    <Container sx={{ pb: 5 }} maxWidth="lg">
      <Card>{content}</Card>
    </Container>
  );
};

export default AdminPage;
