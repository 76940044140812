import {
  Alert,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect } from "react";

const LicenceFormular = ({
  values = {},
  handleSubmit,
  error,
  addItem = false,
}) => {
  const [autoRenewal, setAutoRenewal] = React.useState(false);
  const [featuresOnlineNotes, setfeaturesOnlineNotes] = React.useState(false);

  const getDefaultValue = (value, defValue) => {
    if (value === undefined || value === null) {
      return defValue;
    }
    return value;
  };

  useEffect(() => {
    setAutoRenewal(values.autoRenewal || false);
    setfeaturesOnlineNotes(values.featuresOnlineNotes || false);
  }, [values.autoRenewal, values.featuresOnlineNotes]);

  return (
    //@@@@ add renewalRequestLeft
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        variant="outlined"
        label="Kontaktna osoba"
        name="contactPerson"
        id="contactPerson"
        fullWidth
        required
        sx={{ mt: 1 }}
        defaultValue={values.contactPerson || ""}
      />
      <TextField
        variant="outlined"
        label="Kontaktna osoba email"
        name="contactPersonEmail"
        id="contactPersonEmail"
        fullWidth
        required
        sx={{ mt: 1 }}
        defaultValue={values.contactPersonEmail || ""}
      />
      <TextField
        variant="outlined"
        label="Kontaktna osoba tel"
        name="contactPersonTel"
        id="contactPersonTel"
        fullWidth
        required
        sx={{ mt: 1 }}
        defaultValue={values.contactPersonTel || ""}
      />
      <TextField
        variant="outlined"
        label="Kontaktna osoba poznamka"
        name="contactPersonNote"
        id="contactPersonNote"
        fullWidth
        sx={{ mt: 1 }}
        defaultValue={values.contactPersonNote || ""}
      />
      <TextField
        type="number"
        variant="outlined"
        label="Počet aktivácií"
        name="activationRequestLeft"
        id="activationRequestLeft"
        defaultValue={getDefaultValue(values.activationRequestLeft, 1)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />
      <TextField
        type="number"
        variant="outlined"
        label="Počet aktivácií desktop"
        name="activationPdfRequestLeft"
        id="activationPdfRequestLeft"
        defaultValue={getDefaultValue(values.activationPdfRequestLeft, 1)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />
      <TextField
        type="number"
        variant="outlined"
        label="Počet obnovení licencie"
        name="renewalRequestLeft"
        id="renewalRequestLeft"
        defaultValue={getDefaultValue(values.renewalRequestLeft, 0)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />

      <TextField
        type="number"
        variant="outlined"
        label="Platnosť v mesiacoch"
        name="validity"
        id="validity"
        defaultValue={getDefaultValue(values.validity, 1)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />

      <TextField
        type="number"
        variant="outlined"
        label="Počet revíznych technikov"
        name="technicianCount"
        id="technicianCount"
        defaultValue={getDefaultValue(values.technicianCount, 1)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />
      <TextField
        type="number"
        variant="outlined"
        label="Počet objektov"
        name="liftCount"
        id="liftCount"
        defaultValue={getDefaultValue(values.liftCount, 10)}
        fullWidth
        required
        sx={{ mt: 1 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={autoRenewal}
            onChange={(value) => {
              setAutoRenewal(value.target.checked);
            }}
          />
        }
        label="Automatická obnova"
        id="autoRenewal"
        name="autoRenewal"
        labelPlacement="start"
        sx={{ justifyContent: "space-between", display: "flex" }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={featuresOnlineNotes}
            onChange={(value) => {
              setfeaturesOnlineNotes(value.target.checked);
            }}
          />
        }
        label="Automatická synchronizácia objektov s desktop"
        id="featuresOnlineNotes"
        name="featuresOnlineNotes"
        labelPlacement="start"
        sx={{ justifyContent: "space-between", display: "flex" }}
      />
      {error && (
        <Alert sx={{ m: 1, mb: 0, width: "100%" }} severity="error">
          {error}
        </Alert>
      )}
      <Button type="submit" variant="contained" fullWidth sx={{ mt: 4 }}>
        {addItem ? "Generuj licenciu" : "Uprav licenciu"}
      </Button>
    </Box>
  );
};

export default LicenceFormular;
