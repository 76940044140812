import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Box,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import RestoreIcon from "@mui/icons-material/Restore";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import LoadingSpinner from "../components/UI/LoadingSpinner";
import notesApi from "../api/notesApi";
import _ from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";

import { uiAction } from "../store/ui-slice";
import {
  formatDate,
  handelError,
  normalizeStr,
} from "../components/Helpers/helperFunctions";
import ConfirmDialog from "../components/UI/ConfirmDialog";

const TrashPage = (props) => {
  const dispatch = useDispatch();
  const requestPending = useSelector((state) => state.ui.requestPending);
  const [deletedNotes, setDeletedNotes] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [openDeleteHandler, setOpenDeleteHandler] = React.useState(false);
  const [openRestoreHandler, setOpenRestoreHandler] = React.useState(false);
  const [noteToHandle, setNoteToHandle] = React.useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleCloseDeleteHandler = () => {
    setOpenDeleteHandler(false);
  };
  const handleCloseRestoreHandler = () => {
    setOpenRestoreHandler(false);
  };

  const load = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/trash");
      setDeletedNotes(response.data);
    } catch (err) {
      alert("Chyba load kos data: " + handelError(err));
    }
    dispatch(uiAction.setRequestPending(false));
  }, [dispatch]);

  const confirmRestoreHandler = async () => {
    try {
      const response = await notesApi.put("/api/trash/restore/" + noteToHandle);
      if (response.status === 200) {
        load();
      }
    } catch (err) {
      alert("Chyba kos: " + handelError(err));
    }
  };

  const confirmAgreeHandler = async () => {
    try {
      const response = await notesApi.delete("/api/note/" + noteToHandle);
      if (response.status === 200) {
        load();
      }
    } catch (err) {
      alert("Chyba kos: " + handelError(err));
    }
  };

  let filteredNotes = deletedNotes.filter((note) => {
    const sString = normalizeStr(note.object_name) + normalizeStr(note.content);
    return sString.includes(normalizeStr(searchText));
  });
  let sortedNotes = _.orderBy(filteredNotes, (note) => note.scan_at, ["desc"]);

  const trashContent = (
    <List>
      {sortedNotes.map((note, index) => {
        const primaryText = `${formatDate(note.scan_at)} - ${
          note.duration
        } min (osoby: ${note.person_count})`;
        return (
          <ListItem
            key={index}
            divider
            dense
            sx={{ justifyContent: "space-between" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6">{primaryText}</Typography>
              <Typography
                variant="body"
                sx={{ mt: 0.5 }}
                style={{ display: "inline-block", whiteSpace: "pre-line" }}
              >
                {note.content}
              </Typography>
              <Typography style={{ fontWeight: 600 }} variant="subtitle1">
                {note.object_name}
              </Typography>
            </Box>
            <Box sx={{ minWidth: 70, minHeight: 50 }}>
              <Tooltip title="Obnov">
                <IconButton
                  aria-label="Obnov"
                  size="large"
                  edge="end"
                  onClick={() => {
                    setNoteToHandle(note.id);
                    setOpenRestoreHandler(true);
                  }}
                >
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Vymaž">
                <IconButton
                  edge="end"
                  size="large"
                  onClick={() => {
                    setNoteToHandle(note.id);
                    setOpenDeleteHandler(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );

  let content = (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ ml: 1, pt: 1 }}>
            Kôš
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchText("")} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        {trashContent}
      </Card>
      <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete definitivne zmazať poznamku?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      />
      <ConfirmDialog
        openDeleteHandler={openRestoreHandler}
        handleCloseDeleteHandler={handleCloseRestoreHandler}
        dialogTitle="Naozaj chcete obnoviť poznamku?"
        dialogContent=""
        onAgree={confirmRestoreHandler}
      />
    </Box>
  );

  useEffect(() => {
    dispatch(uiAction.setRequestPending(true));
    load();
  }, [load, dispatch]);

  if (requestPending) {
    content = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default TrashPage;
