const weekday = [
  "Nedeľa",
  "Pondelok",
  "Utorok",
  "Streda",
  "Štvrtok",
  "Piatok",
  "Sobota",
];

export const normalizeStr = (src) => {
  if (!src) {
    return "";
  }
  return src
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

export const resetLastCheckTime = () => {
  localStorage.setItem("lastChecked", new Date().getTime());
};

export const handelError = (err) => {
  if (err.response && err.response.data) {
    let errStr = err.response.data.message;
    if (err.response.data.reason.length) {
      errStr +=
        ", " +
        err.response.data.reason
          .map((item) => {
            return `${item.msg} (${item.param})`;
          })
          .join(", ");
    }
    return errStr;
  } else {
    if (err.response) {
      if (err.response.status === 404) {
        return "Hľadaná stránka neexistuje.";
      }
    } else {
      return err.message;
    }
  }
};

export const formatDate = (src, addWeekDay = true) => {
  let res = "";
  let dtObj = new Date(src);
  let weekDayStr = ` - ${weekday[dtObj.getDay()]}`;
  if (!addWeekDay) {
    weekDayStr = "";
  }
  res = `${dtObj.getDate()}.${
    dtObj.getMonth() + 1
  }.${dtObj.getFullYear()}${weekDayStr}`;
  return res;
};

export const countOccurence = (str, ch) => {
  let count = 0;
  for (let s of str) {
    if (s === ch) {
      count++;
    }
  }
  return count;
};

export const prepareCsvStr = (str) => {
  let res = "";
  if (str) {
    for (let s of str) {
      if (s === "\n") {
        res += "-";
      } else if (s === ",") {
        res += ";";
      } else {
        res += s;
      }
    }
  }
  return res;
};

const diacritics = {
  a: "áÁä",
  c: "čČ",
  d: "ďĎ",
  e: "éÉěĚ",
  i: "íÍ",
  l: "ľĺĽĹ",
  n: "ňŇ",
  o: "óÓô",
  r: "řŘ",
  s: "Šš",
  t: "ťŤ",
  u: "úÚ",
  y: "ýÝ",
  z: "žŽ",
};

export const replaceDiacritics = (text) => {
  let res = "";
  for (let c of text) {
    let c_added = false;
    for (let toLetter in diacritics) {
      if (diacritics[toLetter].indexOf(c) !== -1) {
        res += toLetter;
        c_added = true;
        break;
      }
    }
    if (!c_added) {
      res += c.toLocaleLowerCase();
    }
  }
  return res;
};

export const replace_commas_new_lines = (origin, replacement) => {
  if (replacement === undefined) {
    replacement = "";
  }
  var res = origin;
  res = res.replace(/,/g, replacement);
  res = res.replace(/\n/g, replacement);
  return res;
};

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};

export const translate_date2google = (origin) => {
  let res = "";
  if (origin !== "" && origin.length >= 10) {
    let d = new Date(
      origin.substr(0, 4),
      Number(origin.substr(5, 2)) - 1,
      origin.substr(8, 2)
    );
    res =
      addZero(d.getDate()) +
      "/" +
      addZero(d.getMonth() + 1) +
      "/" +
      d.getFullYear();
  }
  return res;
};
