import React from "react";

import classes from "./InfoModal.module.css";

import Modal from "./Modal";
import Button from "./Button";

const InfoModal = (props) => {
  return (
    <Modal>
      <h2 style={{ textAlign: "center" }}>{props.data.title}</h2>
      <div className={classes.control}>
        <label>{props.data.question}</label>
      </div>
      <div className={classes.actions}>
        <Button
          type="button"
          label={"Ok"}
          onClick={(event) => {
            props.onOk();
          }}
        />
      </div>
    </Modal>
  );
};

export default InfoModal;
