import React from "react";
import { Card, Container } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "../api/notesApi";

import { authAction } from "../store/auth-slice";
import SingUpFormular from "../components/Signup/SingUpFormular";
import { handelError } from "../components/Helpers/helperFunctions";
import { useHistory } from "react-router-dom";

const SignUp = () => {
  const [error, setError] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const getCurrentUser = async () => {
    try {
      const response = await api.get("/api/user/current");
      dispatch(authAction.setUserData(response.data));
    } catch (err) {
      alert(handelError(err));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      let response = await api.post("/public/signup", {
        email: event.target.email.value,
        password: event.target.password.value,
        password_repeat: event.target.password_repeat.value,
        name: event.target.name.value,
        licenceNumber: event.target.licence.value,
      });
      response = await api.post("/public/signin", {
        email: event.target.email.value,
        password: event.target.password.value,
      });
      dispatch(authAction.setToken(response.data.token));
      getCurrentUser();
      history.replace("/objects");
    } catch (err) {
      setError(handelError(err));
    }
  };
  return (
    <Container component="main" maxWidth="sm">
      <Card sx={{ mt: "10%", mb: "20%" }}>
        <SingUpFormular handleSubmit={handleSubmit} error={error} />
      </Card>
    </Container>
  );
};

export default SignUp;
