import { createSlice } from "@reduxjs/toolkit";
import { resetLastCheckTime } from "../components/Helpers/helperFunctions";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthentificated: false,
    token: "",
    userName: "",
    userData: null,
  },
  reducers: {
    setUserData(state, action) {
      resetLastCheckTime();
      state.userData = action.payload;
    },
    setToken(state, action) {
      localStorage.setItem("qrAppToken", action.payload);
      state.token = action.payload;
      state.isAuthentificated = true;
    },
    clearToken(state) {
      localStorage.removeItem("qrAppToken");
      state.token = "";
      state.isAuthentificated = false;
      state.userData = null;
    },
  },
});

export const authAction = authSlice.actions;

export default authSlice;
