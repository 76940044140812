import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import TagPicker from "../components/Tags/TagPicker";
import notesApi from "../api/notesApi";
import NoteForm from "../components/Notes/NoteForm";
import { handelError } from "../components/Helpers/helperFunctions";

const NoteEdit = (props) => {
  const params = useParams();
  const history = useHistory();
  const objects = useSelector((state) => state.data.objectData);

  const [note2edit, setNote2edit] = useState(null);
  const [objName, setObjName] = useState("");
  const [tags, setTags] = useState([]);
  const [showTagPicker, setShowTagPicker] = useState(false);

  let noteId = parseInt(params.noteId);

  const onFormClose = () => {
    history.goBack();
    //history.push(`/notes`);
  };

  const onSubmitFormHandler = async (newNote) => {
    try {
      let api_url = "/api/note/" + newNote.id;
      let resp = "";

      resp = await notesApi.put(api_url, { ...newNote });
      if (resp.status === 200) {
        const tagResp = await notesApi.get("/api/note_tag/" + noteId);
        for (let tag of tagResp.data) {
          let delete_old = true;
          for (let newTag of tags) {
            if (tag.id === newTag.id) {
              delete_old = false;
            }
          }
          if (delete_old) {
            await notesApi.delete("/api/note_tag/" + noteId + "/" + tag.id);
          }
        }
        for (let newTag of tags) {
          let add_new = true;
          for (let tag of tagResp.data) {
            if (tag.id === newTag.id) {
              add_new = false;
            }
          }
          if (add_new) {
            await notesApi.post("/api/note_tag", {
              note_id: noteId,
              tag_id: newTag.id,
            });
          }
        }
        history.goBack();
      } else {
        alert("Nastala chyba, code status: " + resp.status);
      }
    } catch (err) {
      alert("Chyba pri ukladani poznamky: " + handelError(err));
    }
  };

  const fetchNote = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/note/" + noteId);
      for (let object of objects) {
        if (object.id === response.data.object_id) {
          setObjName(object.name);
          break;
        }
      }
      const tagResp = await notesApi.get("/api/note_tag/" + noteId);
      setTags(tagResp.data);
      setNote2edit(response.data);
    } catch (err) {
      alert("Chyba fetch notes: " + handelError(err));
    }
  }, [noteId, objects]);

  const onShowTagPicker = () => {
    setShowTagPicker(true);
  };

  const onCloseTagPickerHandler = () => {
    setShowTagPicker(false);
  };
  const onTagAccepted = (newTag) => {
    let tag_arr = [];
    let add_new = true;
    for (let tag of tags) {
      tag_arr.push(tag);
      if (tag.id === newTag.id) {
        add_new = false;
      }
    }
    if (add_new) {
      tag_arr.push(newTag);
    }
    setTags(tag_arr);
  };
  const onRemoveTagHandler = (tagId) => {
    let tag_arr = [];
    for (let tag of tags) {
      if (tag.id !== tagId) {
        tag_arr.push(tag);
      }
    }
    setTags(tag_arr);
  };

  useEffect(() => {
    fetchNote();
  }, [fetchNote]);

  return (
    <Fragment>
      {showTagPicker && (
        <TagPicker close={onCloseTagPickerHandler} accepted={onTagAccepted} />
      )}
      <NoteForm
        object_name={objName}
        onClose={onFormClose}
        onSubmitFormHandler={onSubmitFormHandler}
        isNewNote={false}
        note2edit={note2edit}
        showTagPicker={onShowTagPicker}
        removetag={onRemoveTagHandler}
        tags={tags}
      />
    </Fragment>
  );
};

export default NoteEdit;
