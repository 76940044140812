import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery,
  List,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

import _ from "lodash";
import PreddefNote from "../Notes/PreddefNote";
import EditPreddefNoteModal from "./EditPreddefNoteModal";
import notesApi from "../../api/notesApi";
import ConfirmDialog from "../UI/ConfirmDialog";
import { uiAction } from "../../store/ui-slice";
import { replaceDiacritics } from "../Helpers/helperFunctions";

const PreddefNotesList = ({ notesData, fetchNotes }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatch = useDispatch();
  const { searchText, sortOrder, sortType } = useSelector(
    (state) => state.ui.preddefPageState
  );

  const [openDeleteHandler, setOpenDeleteHandler] = React.useState(false);
  const [noteId, setNodeId] = React.useState(-1);

  const handleCloseDeleteHandler = () => {
    setOpenDeleteHandler(false);
  };
  const onDeleteHandler = (id) => {
    setNodeId(id);
    setOpenDeleteHandler(true);
  };

  const confirmAgreeHandler = async () => {
    let response = await notesApi.delete("/api/pre_note/" + noteId);

    if (response.status === 200) {
      setNodeId(-1);
      fetchNotes();
    } else {
      alert("Nastala chyba pri mazani poznamky: " + response.status);
    }
  };

  const filteredNotes = notesData.filter((note) => {
    return replaceDiacritics(note.name).includes(replaceDiacritics(searchText));
  });
  const sortNotes = _.orderBy(
    filteredNotes,
    (note) => (sortType === "date" ? note.created_at : note.name),
    sortOrder
  );

  const onSortOrderChangeHandler = () => {
    dispatch(
      uiAction.setPreddefPageSortOrder(sortOrder === "asc" ? "desc" : "asc")
    );
  };

  let notesContent = (
    <List>
      {sortNotes.map((note) => {
        return (
          <PreddefNote
            key={note.id}
            id={note.id}
            content={note.name}
            fetchNotes={fetchNotes}
            // editHandler={onEditNoteHandler}
            deleteHandler={onDeleteHandler}
          />
        );
      })}
    </List>
  );
  if (filteredNotes.length === 0) {
    notesContent = (
      <p className="centered">Nenašiel som žiadne vyhovujúce poznamky!</p>
    );
  }
  return (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ ml: 1, pt: 1 }}>
            Preddefinované poznámky
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              value={searchText}
              onChange={(event) => {
                dispatch(uiAction.setPreddefPageSearchText(event.target.value));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        dispatch(uiAction.setPreddefPageSearchText(""))
                      }
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <EditPreddefNoteModal isEdit={false} fetchNotes={fetchNotes} />
          </Box>
        </Box>
        <Box
          sx={{
            mr: 1,
            ml: 1,
            flexDirection: matches ? "row" : "column",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            sx={{ minWidth: 200 }}
            margin="dense"
            id="sortetSource"
            name="sortetSource"
            label="Usporiadaj podľa"
            select
            fullWidth={!matches}
            value={sortType}
            onChange={(item) => {
              dispatch(uiAction.setPreddefPageSortType(item.target.value));
            }}
          >
            <MenuItem value={"date"}>dátumu pridania</MenuItem>
            <MenuItem value={"abcd"}>abecedy</MenuItem>
          </TextField>
          <Button
            fullWidth={!matches}
            onClick={onSortOrderChangeHandler}
            sx={{ m: 1 }}
            variant="contained"
          >
            {sortOrder === "asc" ? "Vzostupne" : "Zostupne"}
          </Button>
        </Box>
        {notesContent}
      </Card>
      <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete zmazať poznánku?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      />
    </Box>
  );
};

export default PreddefNotesList;
