import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import notesApi from "../../api/notesApi";
import downloadBlobAsFile from "../Helpers/fileDownload";
import {
  formatDate,
  replace_commas_new_lines,
  translate_date2google,
} from "../Helpers/helperFunctions";
import { utils, writeFile } from "xlsx";

export default function ExportModalDialog({ values, matches, data, ...rest }) {
  const [open, setOpen] = React.useState(false);

  const getPdf = async (pdfData, pdfType, filename) => {
    let res = await notesApi.post(
      "api/note/pdf",
      {
        filename: "test",
        content: pdfData,
        pdfType,
      },
      {
        responseType: "blob",
      }
    );
    if (res.status === 200) {
      downloadBlobAsFile(res.data, filename, "application/pdf");
    }
  };

  const handlerPDFperLift = async () => {
    let pdfData = [];
    for (let note of data) {
      let add = true;
      for (let pdata of pdfData) {
        if (pdata.object_name === note.object_name) {
          add = false;
          pdata.notes.push(note);
          break;
        }
      }
      if (add) {
        pdfData.push({
          object_name: note.object_name,
          notes: [note],
        });
      }
    }
    getPdf(
      pdfData,
      "pageLift",
      `poznamky-po-vytahoch-${new Date().getDay()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}.pdf`
    );
  };

  const handlerPDF = async () => {
    getPdf(
      data,
      "all",
      `poznamky-${new Date().getDay()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}.pdf`
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const prepare_note = (note) => {
    return `${replace_commas_new_lines(
      note.object_name,
      "-"
    )},${translate_date2google(note.created_at)},${replace_commas_new_lines(
      note.content.trim(),
      "-"
    )} - trvanie: ${note.duration}\n`;
  };

  const saveFile = async (month, isGoogle = true) => {
    let data2write = "";
    for (let note of data) {
      if (data2write === "") {
        data2write = "Subject,Start date,Description\n";
      } else {
        data2write += prepare_note(note);
      }
    }
    if (data2write === "") {
      alert("V danom mesiaci nie sú žiadne poznámky!");
      return;
    }
    const fileName = `kalendar_${new Date().toLocaleDateString()}.csv`;
    downloadBlobAsFile(data2write, fileName);
    // let filename =
    //   FileSystem.cacheDirectory +
    //   "share_content" +
    //   new Date().getTime() +
    //   ".csv";
    // await FileSystem.writeAsStringAsync(filename, data2write);
    // try {
    //   await Sharing.shareAsync(filename);
    // } catch (err) {
    //   Alert.alert("catch err: " + err.message);
    // }
  };

  const exportFile = () => {
    const ws = utils.json_to_sheet(
      data.map((note) => {
        return {
          datum: formatDate(note.scan_at),
          trvanie: note.duration,
          poznamka: note.content,
          objekt: note.object_name,
        };
      })
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    writeFile(wb, `poznanmky-${new Date().toISOString()}.xlsx`);
  };

  return (
    <Box sx={{ width: !matches ? "100%" : undefined }}>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        fullWidth={!matches}
        onClick={handleClickOpen}
      >
        Export
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Export poznámok</DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions sx={{ flexDirection: matches ? "row" : "column" }}>
            <Button
              sx={{ m: 0.3 }}
              fullWidth={!matches}
              onClick={handleClose}
              color="error"
              variant="contained"
            >
              Zavri
            </Button>
            <Button
              sx={{ m: 0.3 }}
              fullWidth={!matches}
              variant="contained"
              onClick={() => {
                exportFile();
              }}
            >
              Excel
            </Button>
            <Button
              sx={{ m: 0.3 }}
              fullWidth={!matches}
              variant="contained"
              onClick={() => {
                saveFile();
              }}
            >
              Google kalendár
            </Button>
            <Button
              sx={{ m: 0.3 }}
              fullWidth={!matches}
              variant="contained"
              onClick={handlerPDF}
            >
              Pdf dokument
            </Button>
            <Button
              sx={{ m: 0.3 }}
              fullWidth={!matches}
              variant="contained"
              onClick={handlerPDFperLift}
            >
              Pdf po objektoch
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
