import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";
import LicenceFormular from "./LicenceFormular";
import { v4 as uuidv4 } from "uuid";

export default function EditLicenceModal({ values = {}, isEdit, load }) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    load();
  };
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setError(null);
    try {
      const licenceData = {
        contactPerson: event.target.contactPerson.value,
        contactPersonEmail: event.target.contactPersonEmail.value,
        contactPersonTel: event.target.contactPersonTel.value,
        contactPersonNote: event.target.contactPersonNote.value,
        validity: parseInt(event.target.validity.value),
        technicianCount: parseInt(event.target.technicianCount.value),
        liftCount: parseInt(event.target.liftCount.value),
        renewalRequestLeft: parseInt(event.target.renewalRequestLeft.value),
        activationRequestLeft: parseInt(
          event.target.activationRequestLeft.value
        ),
        activationPdfRequestLeft: parseInt(
          event.target.activationPdfRequestLeft.value
        ),
        autoRenewal: event.target.autoRenewal.checked,
        featuresOnlineNotes: event.target.featuresOnlineNotes.checked,
      };
      if (!isEdit) {
        licenceData.licenceNumber = uuidv4();
        const response = await notesApi.post("/api/admin/licence", licenceData);
        if (response.status === 201) {
          handleClose();
          alert("Licencia bola úspešne vygenerovaná");
        }
      } else {
        const response = await notesApi.put(
          "/api/admin/licence/" + values.id,
          licenceData
        );
        if (response.status === 200) {
          handleClose();
          alert("Licencia bola úspešne upravená");
        }
      }
    } catch (err) {
      setError(handelError(err));
    }
  };

  return (
    <span>
      <Button sx={{ m: 0.5 }} variant="contained" onClick={handleClickOpen}>
        {isEdit ? "Uprav" : "Pridaj licenciu"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogTitle>Licencia</DialogTitle>
          <DialogContent>
            <LicenceFormular
              values={values}
              handleSubmit={onSubmitHandler}
              error={error}
              addItem={!isEdit}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>
              Zavri
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
}
