import React, { useState, useCallback, useEffect, Fragment } from "react";

import classes from "./PreddefPicker.module.css";
import Button from "../UI/Button";
import notesApi from "../../api/notesApi";
import LoadingSpinner from "../UI/LoadingSpinner";
import Modal from "../UI/Modal";
import { handelError } from "../Helpers/helperFunctions";

const PreddefPicker = (props) => {
  const [notes, setNotes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  const onChangeSearchHandler = (event) => {
    setSearchText(event.target.value);
  };

  const fetchNotes = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/pre_note");
      response.data.sort((lh, rh) => {
        // tu potom poriadne poriesit zoradenie od najnovsej
        return rh.created_at > lh.created_at;
      });
      setNotes(response.data);
      setLoading(false);
    } catch (err) {
      alert("Chyba fetch preddef notes: " + handelError(err));
    }
  }, []);
  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  let filteredNotes = notes.filter((note) => {
    return note.name.toLowerCase().includes(searchText.toLowerCase());
  });

  const notesContent = (
    <ul>
      {filteredNotes.map((note) => {
        return (
          <li
            className={classes.note}
            key={note.id}
            onDoubleClick={() => {
              props.onHide(note.name);
            }}
          >
            {note.name}
          </li>
        );
      })}
    </ul>
  );

  useEffect(() => {
    setLoading(true);
    fetchNotes();
  }, [fetchNotes]);

  let modalContent = (
    <section className={classes.preddefNotes}>
      <Fragment>
        <h1 style={{ textAlign: "center" }}>{"Vyber z preddefinovaných"}</h1>
        <span>
          <label className={classes.label}>Vyhľadaj</label>
          <input
            className={classes.input}
            onChange={onChangeSearchHandler}
            value={searchText}
            autoFocus={true}
          />
        </span>
        {notesContent}
        <div className={classes.actions}>
          <Button
            type="button"
            label={"Schovaj"}
            onClick={(event) => {
              event.preventDefault();
              props.onHide();
            }}
          />
        </div>
      </Fragment>
    </section>
  );
  if (loading) {
    modalContent = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return <Modal>{modalContent}</Modal>;
};

export default PreddefPicker;
