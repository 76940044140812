import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import notesApi from "../../api/notesApi";
import ObjectForm from "./ObjectForm";
import { handelError } from "../Helpers/helperFunctions";

export default function EditObjectModal({
  values = {},
  matches,
  fetchObjects,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError(false);
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(false);
    let response;
    try {
      if (values.id) {
        response = await notesApi.put("/api/object/" + values.id, {
          name: event.target.objName.value,
          description: event.target.description.value,
        });
        if (response.status === 200) {
          fetchObjects();
          handleClose();
        } else {
          if (response.status === 400) {
          } else {
            throw new Error("Status code (" + response.status + ")");
          }
        }
      } else {
        const objData = {
          name: event.target.objName.value,
          description: event.target.description.value,
        };
        response = await notesApi.post("/api/object", { ...objData });
        if (response.status === 200) {
          fetchObjects();
          handleClose();
        } else {
          if (response.status === 400) {
          } else {
            throw new Error("Status code (" + response.status + ")");
          }
        }
      }
    } catch (err) {
      setError("Nastala chyba: " + handelError(err));
    }
  };

  return (
    <Box sx={{ width: !matches ? "100%" : undefined }}>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        fullWidth={!matches}
        onClick={handleClickOpen}
      >
        {values.objName ? "Uprav objekt" : "Pridaj objekt"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              {values.objName ? "Uprav objekt" : "Nový objekt"}
            </DialogTitle>
            <DialogContent>
              <ObjectForm values={values} error={error} />
            </DialogContent>
            <DialogActions>
              <Button color="error" variant="contained" onClick={handleClose}>
                Zavri
              </Button>
              <Button variant="contained" type="submit">
                {values.objName ? "Uprav objekt" : "Pridaj objekt"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
