import React from "react";

import { formatDate } from "../Helpers/helperFunctions";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TagBox from "../UI/TagBox";
import { IconButton, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoteItem = ({
  id,
  created_at,
  content,
  object_name,
  duration,
  person_count,
  tags = [],
  deleteHandler,
  editHandler,
  matches,
}) => {
  const primaryText = `${formatDate(
    created_at
  )} - ${duration} min (osoby: ${person_count})`;
  let tagContent = (
    <Box sx={{ display: "flex", flexDirection: matches ? "row" : "column" }}>
      {tags.map((tag) => {
        return <TagBox tag={tag} key={tag.id} matches={matches} />;
      })}
    </Box>
  );
  return (
    <ListItem divider dense sx={{ justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">{primaryText}</Typography>
        {tagContent}
        <Typography
          variant="body"
          sx={{ mt: 0.5 }}
          style={{ display: "inline-block", whiteSpace: "pre-line" }}
        >
          {content}
        </Typography>
        <Typography style={{ fontWeight: 600 }} variant="subtitle1">
          {object_name}
        </Typography>
      </Box>
      <Box sx={{ minWidth: 70, minHeight: 50 }}>
        <IconButton
          edge="end"
          onClick={() => {
            editHandler(id);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          edge="end"
          onClick={() => {
            deleteHandler(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </ListItem>
  );
};

export default NoteItem;
