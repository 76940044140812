import React from "react";
import {
  ListItemText,
  ListItemButton,
  ListItem,
  IconButton,
  Stack,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ObjectDetail from "./ObjectDetail";

const ObjectItem = (props) => {
  const handleClickOpen = () => {
    const values = {
      id: props.id,
      name: `${props.name} - ${props.description}`,
    };
    props.onAddNote(values);
  };

  return (
    <ListItem
      divider
      dense
      secondaryAction={
        <Stack direction="row">
          <ObjectDetail objectId={props.id} />
          <IconButton
            aria-label="Pridaj"
            size="small"
            onClick={handleClickOpen}
          >
            <AddCircleIcon size={14} />{" "}
          </IconButton>
        </Stack>
      }
      disablePadding
    >
      <ListItemButton
        selected={props.isSelected}
        onClick={() => {
          props.onClick(props.id);
        }}
      >
        <ListItemText
          primary={` ${props.name} - `}
          secondary={props.description}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ObjectItem;
