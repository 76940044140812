import React, { useEffect, useRef } from "react";

import Modal from "../UI/Modal";
import Button from "../UI/Button";
import notesApi from "../../api/notesApi";

import classes from "./EditTag.module.css";
import { handelError } from "../Helpers/helperFunctions";

const EditTag = (props) => {
  const nameInputRef = useRef();
  const colorInputRef = useRef();
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      let response;
      let data = {
        name: nameInputRef.current.value,
        color: colorInputRef.current.value,
      };
      if (props.item2edit.id) {
        response = await notesApi.put("/api/tag/" + props.item2edit.id, {
          ...data,
        });
      } else {
        response = await notesApi.post("/api/tag", {
          ...data,
        });
      }
      if (response.status === 200) {
        props.onClose(true);
      } else {
        throw new Error(response.status);
      }
    } catch (err) {
      alert("Chyba ukladania tag: " + handelError(err));
    }
  };

  useEffect(() => {
    if (props.item2edit.id) {
      nameInputRef.current.value = props.item2edit.name;
      colorInputRef.current.value = props.item2edit.color;
    }
  }, [props.item2edit]);

  return (
    <Modal onClose={null}>
      <h2 style={{ textAlign: "center" }}>
        {!props.item2edit.id ? "Nový tag" : "Úprava tagu"}
      </h2>
      <div className={classes.control}>
        <label htmlFor="text">Názov tagu</label>
        <input type="text" ref={nameInputRef} />
      </div>
      <div className={classes.colorPicker}>
        <label htmlFor="text">Vyber farbu</label>
        <input type="color" id="color-picker" ref={colorInputRef} />
      </div>
      <div className={classes.actions}>
        <Button
          type="button"
          label={"Zavri"}
          onClick={(event) => {
            event.preventDefault();
            props.onClose();
          }}
        />
        <Button
          type="button"
          label={!props.item2edit.id ? "Ulož tag" : "Uprav tag"}
          onClick={onSubmitHandler}
        />
      </div>
    </Modal>
  );
};

export default EditTag;
