import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

const ConfirmDialog = ({
  onAgree,
  openDeleteHandler,
  handleCloseDeleteHandler,
  dialogContent,
  dialogTitle,
  confirmData,
}) => {
  return (
    <Dialog
      open={openDeleteHandler}
      onClose={handleCloseDeleteHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteHandler}>Zrušiť</Button>
        <Button
          onClick={() => {
            onAgree(confirmData);
            handleCloseDeleteHandler();
          }}
          autoFocus
        >
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
