import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Box,
  ListItem,
  ListItemText,
  List,
  TextField,
} from "@mui/material";
import notesApi from "../../api/notesApi";
import { handelError } from "../Helpers/helperFunctions";

export default function PreddefPickerModal({ matches, handlePick, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const [notes, setNotes] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const handleClickOpen = async () => {
    try {
      const response = await notesApi.get("/api/pre_note");
      response.data.sort((lh, rh) => {
        // tu potom poriadne poriesit zoradenie od najnovsej
        return rh.created_at > lh.created_at;
      });
      setNotes(response.data);
      setLoading(false);
    } catch (err) {
      alert("Chyba fetch preddef notes: " + handelError(err));
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const handleSubmit = async (event) => {
    handlePick(event);
    handleClose();
  };

  let filteredNotes = notes.filter((note) => {
    return note.name.toLowerCase().includes(searchText.toLowerCase());
  });

  let content = (
    <Box>
      <TextField
        label="Vyhľadaj"
        fullWidth
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
      />
      <List>
        {filteredNotes.map((item, index) => {
          return (
            <ListItem
              key={index}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                handleSubmit(item);
              }}
            >
              <ListItemText>{item.name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  if (loading) {
    content = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        color="primary"
        variant="contained"
        fullWidth={!matches}
        sx={{ mb: 0.5, ml: 1 }}
      >
        Vyber z predvolených
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ height: "80%" }}>
        <DialogContent>
          <DialogTitle>Vyber z preddefinovaných</DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="error">
              Zavri
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
