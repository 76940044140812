import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import { dataAction } from "../store/data-slice";
import { uiAction } from "../store/ui-slice";
import notesApi from "../api/notesApi";
import PreddefNotesList from "../components/PreddefNotes/PreddefNotesList";
import { handelError } from "../components/Helpers/helperFunctions";

const PreddefNotes = (props) => {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.data.preddefNotesData);
  const requestPending = useSelector((state) => state.ui.requestPending);

  const fetchNotes = useCallback(async () => {
    dispatch(uiAction.setRequestPending(true));
    try {
      const response = await notesApi.get("/api/pre_note");
      response.data.sort((lh, rh) => {
        // tu potom poriadne poriesit zoradenie od najnovsej
        return rh.created_at > lh.created_at;
      });
      dispatch(dataAction.setPreddefNotesData(response.data));
    } catch (err) {
      alert("Chyba fetch notes: " + handelError(err));
    }
    dispatch(uiAction.setRequestPending(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(uiAction.setSearchText(""));
    fetchNotes();
  }, [fetchNotes, dispatch]);

  let content = <PreddefNotesList notesData={notes} fetchNotes={fetchNotes} />;
  if (requestPending) {
    content = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  return <Fragment>{content}</Fragment>;
};

export default PreddefNotes;
