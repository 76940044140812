import {
  Button,
  Typography,
  Menu,
  MenuItem,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import { authAction } from "../../store/auth-slice";
import { dataAction } from "../../store/data-slice";

const MainNavigation = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuthentificated);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onHomeClicked = () => {
    history.push("/objects");
  };
  const onLogoutHandler = () => {
    dispatch(dataAction.clearAllData());
    dispatch(authAction.clearToken());
    history.replace("/login");
  };
  const navData = [
    { navTo: "/objects", displayText: "Zoznam objektov" },
    { navTo: "/notes", displayText: "Poznámky" },
    { navTo: "/preddefNotes", displayText: "Preddefinované poznámky" },
    { navTo: "/tags", displayText: "Tagy" },
    { navTo: "/trash", displayText: "Kôš" },
  ];

  if (!matches) {
    navData.push({ navTo: "/", displayText: "Odhlásiť sa" });
  }

  const menuItems = navData.map((item) => {
    return (
      <MenuItem
        key={item.displayText}
        onClick={() => {
          if (item.navTo === "/") {
            onLogoutHandler();
          } else {
            history.push(item.navTo);
          }
          handleMenuClose();
        }}
      >
        {item.displayText}
      </MenuItem>
    );
  });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={"primary-menu-id"}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems}
    </Menu>
  );

  let navContent = null;
  if (isAuth && matches) {
    navContent = navData.map((nd) => {
      const itemColor =
        location.pathname.indexOf(nd.navTo) === -1 ? "#88dfdf" : "#e6fcfc";
      return (
        <NavLink
          key={nd.navTo}
          to={nd.navTo}
          style={{ textDecoration: "none" }}
        >
          <Typography
            color="primary"
            sx={{
              pl: 2,
              textDecoration: "none",
              color: itemColor,
              "&:hover": {
                color: "#e6fcfc",
              },
            }}
            variant="h6"
          >
            {nd.displayText}
          </Typography>
        </NavLink>
      );
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bgcolor: "#008080",
        top: 0,
        justifyContent: "space-between",
        pl: 1,
        pr: 1,
        pt: 0.5,
        justifyItems: "center",
      }}
    >
      <Typography
        variant="h5"
        color="white"
        sx={{
          cursor: "pointer",
          alignSelf: "center",
        }}
        onClick={onHomeClicked}
      >
        Qr poznámky {process.env.REACT_APP_DEVEL}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
        }}
      >
        {navContent}
      </Box>
      {isAuth && matches && (
        <Button
          variant="contained"
          color="success"
          onClick={onLogoutHandler}
          sx={{ margin: 0.5 }}
        >
          Odhlásiť sa
        </Button>
      )}
      {isAuth && !matches && (
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleProfileMenuOpen}
        >
          <MenuIcon />
        </IconButton>
      )}
      {renderMenu}
    </Box>
  );
};

export default MainNavigation;
