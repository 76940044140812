import React, { Fragment, useCallback, useEffect, useState } from "react";

import Modal from "../UI/Modal";
import Button from "../UI/Button";

import notesApi from "../../api/notesApi";

import classes from "./TagPicker.module.css";
import LoadingSpinner from "../UI/LoadingSpinner";
import { handelError } from "../Helpers/helperFunctions";

const TagPicker = (props) => {
  const [loading, setLoading] = useState(true);
  const [tagData, setTagData] = useState([]);

  const getTagData = useCallback(async () => {
    try {
      const response = await notesApi.get("/api/tag");
      setTagData(response.data);
    } catch (err) {
      alert("Chyba get tags: " + handelError(err));
    }
    setLoading(false);
  }, []);

  let tagContent = (
    <ul className={classes.tagList}>
      {tagData.map((tag) => {
        return (
          <li
            onDoubleClick={() => {
              props.accepted({ ...tag });
              props.close();
            }}
            key={tag.id}
          >
            <label
              style={{ color: tag.color }}
              onDoubleClick={() => {
                props.accepted({ ...tag });
                props.close();
              }}
            >
              {tag.name}
            </label>
          </li>
        );
      })}
    </ul>
  );

  let modalContent = (
    <Fragment>
      <h2 style={{ textAlign: "center" }}>Vyber tag</h2>
      {tagContent}
      <div className={classes.actions}>
        <Button
          type="button"
          label={"Zavri"}
          onClick={(event) => {
            event.preventDefault();
            props.close();
          }}
        />
      </div>
    </Fragment>
  );

  if (loading) {
    modalContent = (
      <div className="centered">
        <LoadingSpinner />
      </div>
    );
  }

  useEffect(() => {
    getTagData();
  }, [getTagData]);

  return <Modal onClose={props.close}>{modalContent}</Modal>;
};

export default TagPicker;
