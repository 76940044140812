import React from "react";

import UserInfo from "../components/Helpers/UserInfo";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

const SettingsPage = () => {
  const userData = useSelector((state) => state.auth.userData);
  if (!userData) {
    return <Typography>Chyba, reload</Typography>;
  }
  return (
    <Grid container spacing={3} sx={{ mt: 0 }}>
      <Grid item xs={12} md={5}>
        <UserInfo userData={userData} />
      </Grid>
    </Grid>
  );
};

export default SettingsPage;
