import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    preddefPickerVisible: false,
    requestPending: false,
    requestError: "",
    searchText: "",
    objectPageState: {
      searchText: "",
      selectedObjectId: -1,
    },
    notesPageState: {
      searchText: "",
      dateFilter: {
        from: new Date().getTime() - 1000 * 3600 * 24 * 90,
        to: new Date().getTime() + 1000 * 3600 * 24,
      },
      sortOrder: "desc",
      tagFilter: [],
    },
    preddefPageState: {
      searchText: "",
      sortOrder: "desc",
      sortType: "date",
    },
  },
  reducers: {
    setPreddefPageSearchText(state, action) {
      state.preddefPageState = {
        ...state.preddefPageState,
        searchText: action.payload,
      };
    },
    setPreddefPageSortOrder(state, action) {
      state.preddefPageState = {
        ...state.preddefPageState,
        sortOrder: action.payload,
      };
    },
    setPreddefPageSortType(state, action) {
      state.preddefPageState = {
        ...state.preddefPageState,
        sortType: action.payload,
      };
    },

    setNotesPageSearchText(state, action) {
      state.notesPageState = {
        ...state.notesPageState,
        searchText: action.payload,
      };
    },
    setNotesPageDateFilter(state, action) {
      state.notesPageState = {
        ...state.notesPageState,
        dateFilter: action.payload,
      };
    },
    setNotesPageSortOrder(state, action) {
      state.notesPageState = {
        ...state.notesPageState,
        sortOrder: action.payload,
      };
    },
    setNotesPageTagFilter(state, action) {
      state.notesPageState = {
        ...state.notesPageState,
        tagFilter: [...action.payload],
      };
    },

    tooglePreddefPicker(state) {
      state.preddefPickerVisible = !state.preddefPickerVisible;
    },
    toogleRequestPending(state) {
      state.requestPending = !state.requestPending;
    },
    setRequestPending(state, action) {
      state.requestPending = action.payload;
    },
    setSearchText(state, action) {
      state.searchText = action.payload;
    },
    setObjectPageSearchText(state, action) {
      state.objectPageState = {
        ...state.objectPageState,
        searchText: action.payload,
      };
    },
    setObjectPageSelectedId(state, action) {
      state.objectPageState = {
        ...state.objectPageState,
        selectedObjectId: action.payload,
      };
    },
  },
});

export const uiAction = uiSlice.actions;

export default uiSlice;
