import React from "react";
import {
  Card,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  useMediaQuery,
  List,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import TagItem from "./TagItem";
import { useTheme } from "@mui/material/styles";
import ConfirmDialog from "../UI/ConfirmDialog";
import notesApi from "../../api/notesApi";
import EditTagModal from "./EditTagModal";

const TagsList = ({ fetchTags, tagData }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [openEditTagHandler, setOpenEditTagHandler] = React.useState(false);
  const [openDeleteHandler, setOpenDeleteHandler] = React.useState(false);
  const [tagId, setTagId] = React.useState(-1);
  const [tagEdit, setTagEdit] = React.useState(null);

  const handleCloseEditTagHandler = () => {
    setOpenEditTagHandler(false);
  };

  const handleCloseDeleteHandler = () => {
    setOpenDeleteHandler(false);
  };

  const confirmAgreeHandler = async () => {
    let response;
    try {
      response = await notesApi.delete("/api/tag/" + tagId);

      if (response.status === 200) {
        setTagId(-1);
        fetchTags();
      } else {
        throw new Error("Nastala chyba pri mazani tagu: " + response.status);
      }
    } catch (err) {
      let mess = err.message;
      if (err.message.indexOf("500") !== -1) {
        mess = "Chyba pri mazani tagu - tag sa pravdepodobne používa";
      }
      alert(mess);
    }
  };

  const onEditTagHandler = (id) => {
    for (let tag of tagData) {
      if (tag.id === id) {
        setTagEdit(tag);
        setOpenEditTagHandler(true);
      }
    }
  };
  const onTagDeleteHandler = (id) => {
    // showConfirmDialog(val);
    setTagId(id);
    setOpenDeleteHandler(true);
  };

  const tagContent = (
    <List>
      {tagData.map((tag) => {
        return (
          <TagItem
            key={tag.id}
            id={tag.id}
            name={tag.name}
            color={tag.color}
            editHandler={onEditTagHandler}
            deleteHandler={onTagDeleteHandler}
            matches={matches}
          />
        );
      })}
    </List>
  );
  return (
    <Box sx={{ mt: 1, mb: 10 }}>
      <Card sx={{ pt: 2 }}>
        <Box
          align="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" sx={{ ml: 1, pt: 1 }}>
            Tagy
          </Typography>
          <Box
            textAlign="center"
            sx={{
              mr: 1,
              flexDirection: matches ? "row" : "column",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-basic"
              label="Vyhľadaj"
              variant="outlined"
              // value={searchText}
              onChange={(event) => {
                // setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      // onClick={() => setSearchText("")}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{ m: 1 }}
              variant="contained"
              onClick={() => {
                setTagEdit(null);
                setOpenEditTagHandler(true);
              }}
            >
              Pridaj tag
            </Button>
          </Box>
        </Box>
        {tagContent}
      </Card>
      <EditTagModal
        open={openEditTagHandler}
        handleClose={handleCloseEditTagHandler}
        fetchTags={fetchTags}
        matches={matches}
        tagEdit={tagEdit}
        tags={tagData}
      />
      <ConfirmDialog
        openDeleteHandler={openDeleteHandler}
        handleCloseDeleteHandler={handleCloseDeleteHandler}
        dialogTitle="Naozaj chcete zmazať tag?"
        dialogContent=""
        onAgree={confirmAgreeHandler}
      />
    </Box>
  );
};

export default TagsList;
